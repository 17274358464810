//　自分の記事リスト取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getMyArticleList = async (page) => {
  // クエリパラメータ:ページ番号・取得記事数
  const { data } = await client.get("/my_article/", {
    params: {
      page: page,
      page_size: 20,
    },
  });
  return data;
};

export const useQueryMyArticleListApi = (page) => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["my_article_list", page],
    queryFn: () => getMyArticleList(page),
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};
