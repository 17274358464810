//　PageLayout用Component_ヘッダー

// 外部ライブラリ
import { memo } from "react";
import { useDispatch } from "react-redux";
import { AppBar, Box, Toolbar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// プロジェクト内リソース
import { handleDrawer } from "../../features/drawerSlice";
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { useQueryTagApi } from "../../hooks/useQueryTag";
import { useQueryMessageListApi } from "../../hooks/useQueryMessageListApi";
import { HeaderAppName } from "./HeaderComponents/HeaderAppName";
import { HeaderAvatar } from "./HeaderComponents/HeaderAvatar";
import { HeaderSearchArea } from "./HeaderComponents/HeaderSearchArea";
import { HeaderMessageBadge } from "./HeaderComponents/HeaderMessageBadge";

// ***レンダリング***
export const Header = memo(() => {
  const dispatch = useDispatch();

  const me = useQueryMeApi(); // ユーザーデータ取得
  const tag = useQueryTagApi(); // タグデータ取得
  const message = useQueryMessageListApi(); // あなたへのお知らせデータ取得

  // 必要データローディング状態
  const loading = me.isLoading || tag.isLoading || message.isLoading;

  return (
    <>
      <AppBar
        position="fixed"
        color="header"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        elevation={2}
      >
        <Toolbar>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <HeaderAppName loading={loading} />
          </Box>
          <Box sx={{ display: { md: "none" }, mr: 1 }}>
            <IconButton onClick={() => dispatch(handleDrawer())}>
              <MenuIcon />
            </IconButton>
          </Box>
          {/* 検索エリア */}
          <HeaderSearchArea
            loading={loading}
            tagItems={loading ? [] : tag.data}
            id={!loading && me.data.id}
          />
          <Box sx={{ flexGrow: 1 }} />
          {/* 右側の通知・アバター */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              mr: 2,
            }}
          >
            <HeaderMessageBadge data={message.data} loading={loading} />
            <HeaderAvatar data={me.data} loading={loading} />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
});
