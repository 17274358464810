//　ARTICLE:各検索条件共通_検索結果画面

// 外部ライブラリ
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Box, Typography, Divider, Grid } from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";
import StarIcon from "@mui/icons-material/Star";
import SearchIcon from "@mui/icons-material/Search";

// プロジェクト内リソース
import { useQuerySearchArticleApi } from "../hooks/useQuerySearchArticleApi";
import { ArticleSearchList } from "../Components/ArticleSearch/ArticleSearchList";
import { CustomPagination } from "../Components/Share/CustomPagination";
import { ContentCard } from "../Components/Share/ContentCard";
import { ArticleSearchGlimpseAricleDialog } from "../Components/ArticleSearch/ArticleSearchGlimpseAricleDialog";
import { ScrollBox } from "../Components/Share/ScrollBox";

// ***レンダリング***
export const ArticleSearchResults = () => {
  // URLパラメータから各検索キーを取得
  const { index } = useParams();
  const [searchParams] = useSearchParams();
  const pathname = useLocation().pathname;
  const scope = searchParams.get("scope");
  const author = searchParams.get("author");
  const tags = searchParams.get("tags");
  const keyword = searchParams.get("keyword");
  const bookmarked = searchParams.get("bookmarked");
  const or = searchParams.get("or");

  // URLに応じた固定検索条件設定・表示内容
  const searchProps = {
    "/article_search/all": {
      label: "全ての記事（更新日降順）",
      icon: <FeedIcon />,
    },
    "/article_search/bookmarked": {
      label: "ブックマークした記事",
      icon: <StarIcon />,
      params: { bookmarked: true },
    },
    "/article_search/results": {
      label: "検索結果",
      icon: <SearchIcon />,
      params: {
        author: author,
        bookmarked: bookmarked == "true" ? true : null,
        tags: tags,
        or: or == "OR" ? true : null,
        title: scope == "fulltext" ? null : keyword,
        fulltext: scope == "fulltext" ? keyword : null,
      },
    },
  }[pathname.substring(0, pathname.indexOf("/index"))];

  // 検索結果データ取得
  const { data, isLoading } = useQuerySearchArticleApi({
    ...searchProps.params,
    page: index,
  });

  return (
    <>
      {/* チラ見Dialog */}
      <ArticleSearchGlimpseAricleDialog />
      {/* コンテナ部分 */}
      <ContentCard>
        <Grid container>
          <Grid item xs={12} sm={12} md={5} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "base.contrastText",
              }}
            >
              {searchProps.icon}
              <Typography variant="h7" sx={{ ml: 1 }}>
                {searchProps.label}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CustomPagination
              count={isLoading || !data ? null : data.count}
              loading={isLoading}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 0.6 }} />
        <ScrollBox>
          <ArticleSearchList
            articleList={!isLoading && data ? data.results : []}
            loading={isLoading}
          />
        </ScrollBox>
      </ContentCard>
    </>
  );
};
