// 管理者用_お知らせ新規投稿確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import {
  handleNotificationDialog,
  handlePostNotificationState,
  handleCreateNotificationPreviewTab,
  onClickClearNotificationParams,
  onClickCreateNotificationDialogClose,
  handleAdminNotificationDialog,
} from "../../features/notificationStateSlice";
import { useMutateNotificationApi } from "../../hooks/useMutateNotificationApi";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const PostNotificationCheckDialog = memo(() => {
  const dispatch = useDispatch();

  // お知らせ参照Dialog状態・管理者画面用お知らせDialog状態・新規投稿APIリクエスト状態
  const { notificationDialog, adminNotificationDialog, postNotificationState } =
    useSelector((state) => state.notificationState);

  // 新規投稿APIリクエスト関数
  const { postNotificaitonMutation } = useMutateNotificationApi();

  return (
    <>
      <UpdateDbDialog
        open={postNotificationState.dialog}
        onClickCloseDialog={() =>
          dispatch(handlePostNotificationState({ dialog: false }))
        }
        content={<Typography color="error">この内容で投稿します</Typography>}
        onClickUpdate={() => postNotificaitonMutation.mutate()}
        updating={postNotificationState.updating}
        updated={postNotificationState.updated}
        onClickCheckUpdated={() => {
          dispatch(onClickClearNotificationParams());
          dispatch(
            handlePostNotificationState({ dialog: false, updated: false })
          );
          dispatch(handleCreateNotificationPreviewTab(0));
          dispatch(onClickCreateNotificationDialogClose());
          notificationDialog.dialog && dispatch(handleNotificationDialog());
          adminNotificationDialog.dialog &&
            dispatch(handleAdminNotificationDialog());
        }}
      />
    </>
  );
});
