// 記事投稿・更新・表示状態管理
import { createSlice } from "@reduxjs/toolkit";
import { now } from "../utils/datetime";

// 記事投稿・編集内容初期設定
const defaultParamsState = {
  id: "",
  title: "",
  tags: [],
  owner: {
    display_name: "",
    email: "",
    user_avatar: null,
  },
  created_at: "",
  is_published: true,
  initial_published_at: "",
  last_published_at: "",
  like: [],
  comments: [],
};

// APIリクエスト状態初期設定
const defaultFetchState = {
  dialog: false, // APIリクエスト確認Dialog状態
  updating: false, // APIリクエスト処理中
  updated: false, // APIリクスト完了
};

// 初期値
const initialState = {
  // ***Dialog表示状態管理***
  myArticleDialog: { id: "", dialog: false }, // 自分の記事
  adminArticleDialog: { id: "", dialog: false }, // 管理者用_記事参照
  glimpseArticleDialog: { id: "", dialog: false }, // 記事チラ見
  createArticleDialog: false, // 新規記事作成
  editArticleDialog: false, // 記事編集
  // ***作成中・編集中記事状態管理***
  articleParams: defaultParamsState, // 作成中・編集中記事状態
  createArticlePreviewTab: 0, // 作成中記事プレビュー切替
  editArticlePreviewTab: 0, // 編集中記事プレビュー切替
  // ***APIリクエスト状態管理***
  postArticleState: defaultFetchState, // 新規記事投稿
  putArticleState: defaultFetchState, // 記事更新
  patchIsPublishedArticleState: defaultFetchState, // 記事公開状態更新
  patchAdminBannedArticleState: defaultFetchState, // 管理者用_記事公開禁止状態更新
  deleteArticleState: defaultFetchState, // 記事削除
  previousURL: null, // 更新後の遷移先(元のページ)を保持
  // ***記事コメントDialog表示状態管理***
  createReplyCommentDialog: false, // 返信コメント作成
  editArticleCommentDialog: false, // コメント編集
  deleteArticleCommentCheckDialog: false, // コメント削除確認
  // ***作成中・編集中コメント状態管理***
  articleCreateComment: "", // 作成中新規コメント状態
  articleCreateReplyCommentParams: { ancestor: null, text: "" }, // 作成中返信コメント状態
  articleEditDeleteCommentParams: { id: "", ancestor: null, text: "" }, // 編集中コメント状態
  // ***記事コメントAPIリクエスト状態管理***
  articleCommentUpdating: false,
};

const articleStateSlice = createSlice({
  name: "articleState",
  initialState,
  reducers: {
    // ***Dialog開閉関数***
    // 新規記事作成開く
    onClickCreateArticleDialogOpen: (state, actions) => {
      // 作成中記事状態の初期化・作成/更新日を現在に設定
      state.articleParams = {
        ...defaultParamsState,
        initial_published_at: now,
        last_published_at: now,
      };
      // Dialog開く
      state.createArticleDialog = true;
    },

    // コピー新規開く
    onClickCopyCreateArticleDialogOpen: (state, actions) => {
      // コピー対象記事の内容を作成中記事状態にセット・公開/更新日を現在に設定
      state.articleParams = {
        ...actions.payload,
        initial_published_at: now,
        last_published_at: now,
      };
      // Dialog開く
      state.createArticleDialog = true;
    },

    // 新規記事作成閉じる
    onClickCreateArticleDialogClose: (state) => {
      // Dialog閉じる
      state.createArticleDialog = false;
      // プレビュータブ状態初期化
      state.createArticlePreviewTab = 0;
    },

    // 記事編集開閉
    handleEditArticleDialog: (state, actions) => {
      // Dialog開く時: 対象記事内容を編集中記事状態にセット
      // Dialog閉じる時: プレビュータブ状態初期化
      if (!state.editArticleDialog) {
        state.articleParams = actions.payload;
      } else {
        state.editArticlePreviewTab = 0;
      }
      // Dialog開閉
      state.editArticleDialog = !state.editArticleDialog;
    },

    // 公開・非公開切替確認開閉
    handlePatchIsPublishedArticleDialog: (state) => {
      state.patchIsPublishedArticleState = {
        ...state.patchIsPublishedArticleState,
        dialog: !state.patchIsPublishedArticleState.dialog,
      };
    },

    // 自分の記事確認開閉
    handleMyArticleDialog: (state, actions) => {
      // Dialogを開く時のみ対象記事IDを取得
      if (!state.myArticleDialog.dialog) {
        state.myArticleDialog = { id: actions.payload, dialog: true };
      } else {
        state.myArticleDialog = { ...state.myArticleDialog, dialog: false };
      }
    },

    //  管理者用_記事参照開閉
    handleAdminArticleDialog: (state, actions) => {
      // Dialogを開く時のみ対象記事IDを取得
      if (!state.adminArticleDialog.dialog) {
        state.adminArticleDialog = { id: actions.payload, dialog: true };
      } else {
        state.adminArticleDialog = {
          ...state.adminArticleDialog,
          dialog: false,
        };
      }
    },

    //  記事チラ見開閉
    handleGlimpseArticleDialog: (state, actions) => {
      // Dialogを開く時のみ対象記事IDを取得
      if (!state.glimpseArticleDialog.dialog) {
        state.glimpseArticleDialog = { id: actions.payload, dialog: true };
      } else {
        state.glimpseArticleDialog = {
          ...state.glimpseArticleDialog,
          dialog: false,
        };
      }
    },

    // ***作成中・編集中記事状態管理***
    // 作成中・編集中記事状態変更
    onChangeArticleParams: (state, actions) => {
      state.articleParams = {
        ...state.articleParams,
        ...actions.payload,
      };
    },

    // 新規記事作成中プレビュー切替
    handleCreateArticlePreviewTab: (state, actions) => {
      state.createArticlePreviewTab = actions.payload;
    },

    // 記事編集中プレビュー切替
    handleEditArticlePreviewTab: (state, actions) => {
      state.editArticlePreviewTab = actions.payload;
    },

    // 作成中・編集中記事状態初期化
    onClickClearArticleParams: (state) => {
      state.articleParams = defaultParamsState;
    },

    // ***APIリクエスト状態管理***
    // 新規記事投稿
    handlePostArticleState: (state, actions) => {
      state.postArticleState = {
        ...state.postArticleState,
        ...actions.payload,
      };
    },

    // 記事更新
    handlePutArticleState: (state, actions) => {
      state.putArticleState = { ...state.putArticleState, ...actions.payload };
    },

    //　記事公開状態更新
    handlePatchIsPublishedArticleState: (state, actions) => {
      state.patchIsPublishedArticleState = {
        ...state.patchIsPublishedArticleState,
        ...actions.payload,
      };
    },

    // 管理者用_記事公開禁止状態更新
    handlePatchAdminBannedArticleState: (state, actions) => {
      state.patchAdminBannedArticleState = {
        ...state.patchAdminBannedArticleState,
        ...actions.payload,
      };
    },

    // 記事削除
    handleDeleteArticleState: (state, actions) => {
      state.deleteArticleState = {
        ...state.deleteArticleState,
        ...actions.payload,
      };
    },

    // 更新後の遷移先(元のページ)を記事参照時に保持
    onClickSavePreviousURL: (state, actions) => {
      state.previousURL = actions.payload;
    },

    // ***記事コメントDialog表示状態管理***
    // 返信コメント作成
    handleCreateReplyArticleCommentDialog: (state, actions) => {
      if (!state.createReplyCommentDialog) {
        state.articleCreateReplyCommentParams = actions.payload;
      }
      state.createReplyCommentDialog = !state.createReplyCommentDialog;
    },
    // コメント編集
    handleEditArticleCommentDialog: (state, actions) => {
      if (!state.editArticleCommentDialog) {
        state.articleEditDeleteCommentParams = actions.payload;
      }
      state.editArticleCommentDialog = !state.editArticleCommentDialog;
    },
    // 削除確認
    handleDeleteArticleCommentCheckDialog: (state, actions) => {
      if (!state.deleteArticleCommentCheckDialog) {
        state.articleEditDeleteCommentParams = actions.payload;
      }
      state.deleteArticleCommentCheckDialog =
        !state.deleteArticleCommentCheckDialog;
    },

    // ***作成中・編集中コメント状態管理***
    // 作成中コメント状態変更
    onChangeArticleComment: (state, actions) => {
      state.articleCreateComment = actions.payload;
    },

    // 作成中返信コメント状態変更
    onChangeArticleReplyComment: (state, actions) => {
      state.articleCreateReplyCommentParams = {
        ...state.articleCreateReplyCommentParams,
        text: actions.payload,
      };
    },

    // 編集中コメント状態変更
    onChangeEditArticleComment: (state, actions) => {
      state.articleEditDeleteCommentParams = {
        ...state.articleEditDeleteCommentParams,
        text: actions.payload,
      };
    },

    // ***記事コメントAPIリクエスト状態管理***
    handleArticleCommentUpdating: (state, actions) => {
      state.articleCommentUpdating = actions.payload;
    },
  },
});

export const {
  onChangeArticleParams,
  handleMyArticleDialog,
  handleAdminArticleDialog,
  handleGlimpseArticleDialog,
  onClickCreateArticleDialogOpen,
  onClickCopyCreateArticleDialogOpen,
  onClickCreateArticleDialogClose,
  handleEditArticleDialog,
  handlePatchIsPublishedArticleDialog,
  handlePostArticleState,
  handlePutArticleState,
  handlePatchIsPublishedArticleState,
  handlePatchAdminBannedArticleState,
  handleDeleteArticleState,
  handleCreateArticlePreviewTab,
  handleEditArticlePreviewTab,
  onClickClearArticleParams,
  handleCreateReplyArticleCommentDialog,
  handleEditArticleCommentDialog,
  handleDeleteArticleCommentCheckDialog,
  onChangeArticleComment,
  onChangeArticleReplyComment,
  onChangeEditArticleComment,
  handleArticleCommentUpdating,
  onClickSavePreviousURL,
} = articleStateSlice.actions;

export default articleStateSlice.reducer;
