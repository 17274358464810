// HOME/Welcome・全てのお知らせ用Component_お知らせリスト

// 外部ライブラリ
import { memo } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";

// プロジェクト内リソース
import { isRecently } from "../../utils/datetime";
import { handleNotificationDialog } from "../../features/notificationStateSlice";
import { ListItemBox } from "../Share/ListItemBox";
import { LoadingBar } from "../Share/LoadingBar";

// ***レンダリング***
export const HomeNotificationList = memo((props) => {
  const dispatch = useDispatch();

  // ***props説明***
  // data: お知らせリストデータ
  // isLoading: データローディング状態
  const { data, isLoading } = props;

  return (
    <>
      <LoadingBar loading={isLoading} />
      <List disablePadding>
        {data && data.results.length ? (
          data.results.map((item, index) => {
            return (
              <Box key={index}>
                <ListItem alignItems="center">
                  <ListItemButton
                    onClick={() => dispatch(handleNotificationDialog(item.id))}
                    sx={{ py: 0 }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ListItemBox>
                              <Typography variant="subtitle2">
                                {item.start_date}
                              </Typography>
                            </ListItemBox>
                            {isRecently(item.start_date) && (
                              <FiberNewIcon
                                color="secondary"
                                sx={{ ml: 0.4 }}
                              />
                            )}
                          </Box>
                          <ListItemBox>
                            <Typography
                              color="primary"
                              variant="h6"
                              sx={{ display: "inline-block", mt: 0.4 }}
                            >
                              {item.title}
                            </Typography>
                          </ListItemBox>
                        </>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ mx: 2 }} />
              </Box>
            );
          })
        ) : (
          <Box textAlign="center" sx={{ display: isLoading && "none", mt: 2 }}>
            <Typography sx={{ display: isLoading && "none" }}>
              お知らせはありません
            </Typography>
          </Box>
        )}
      </List>
    </>
  );
});
