// ARTICLE/記事閲覧

// 外部ライブラリ
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Divider, Typography } from "@mui/material";

// プロジェクト内リソース
import { useQueryMeApi } from "../hooks/useQueryMeApi";
import { useQueryArticleApi } from "../hooks/useQueryArticleApi";
import { TextEditor } from "../Components/Share/TextEditor";
import { ArticleTitleArea } from "../Components/Article/ArticleTitleArea";
import { ContentCard } from "../Components/Share/ContentCard";
import { ArticleCommentsPostArea } from "../Components/Article/ArticleCommentsPostArea";
import { ArticleCommentsList } from "../Components/Article/ArticleCommentsList";
import { EditArticleCommentDialog } from "../Components/Article/EditArticleCommentDialog";
import { DeleteArticleCommentCheckDialog } from "../Components/Article/DeleteArticleCommentCheckDialog";
import { CreateReplyArticleCommentDialog } from "../Components/Article/CreateReplyArticleCommentDialog";
import { ArticleMyArticleMenu } from "../Components/Article/ArticleMyArticleMenu";
import { DeleteArticleCheckDialog } from "../Components/ArticleDialog/DeleteArticleCheckDialog";
import { ArticleAdminMenu } from "../Components/Article/ArticleAdminMenu";
import { LoadingCircular } from "../Components/Share/LoadingCircular";
import { ScrollBox } from "../Components/Share/ScrollBox";

// ***レンダリング***
export const Article = () => {
  // URLの記事IDを参照し対象記事データを取得
  const { id } = useParams();
  const article = useQueryArticleApi(id);

  // ユーザーデータ取得
  const me = useQueryMeApi();

  // テキストエディタのインスタンスを保持
  const editorCore = useRef(null);

  // 記事更新APIリクエスト状態
  const { putArticleState } = useSelector((state) => state.articleState);

  // 必要データ読み込み中はローディング表示
  if (article.isLoading || me.isLoading) {
    return (
      <ContentCard>
        <LoadingCircular />
      </ContentCard>
    );
  }

  // 記事データが無い場合はエラー表示
  if (!article.data) {
    return (
      <ContentCard>
        <Box
          sx={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography>記事が見つかりませんでした</Typography>
            <Typography>
              （この記事は削除されたか、非公開の可能性があります）
            </Typography>
          </Box>
        </Box>
      </ContentCard>
    );
  }

  // 記事データがある場合はコンテナをレンダリング
  return (
    <>
      {/* 削除確認Dialog */}
      <DeleteArticleCheckDialog id={id} />
      {/* 返信コメント作成・コメント編集・削除確認Dialog */}
      <CreateReplyArticleCommentDialog id={id} />
      <EditArticleCommentDialog id={id} />
      <DeleteArticleCommentCheckDialog id={id} />
      {/* コンテナ部分 */}
      <ContentCard>
        {/* 上部メニュー・タイトル */}
        <Box sx={{ pb: 1.2, px: { xs: 1, sm: 2, md: 4 } }}>
          {me.data.id == article.data.owner.id && (
            <ArticleMyArticleMenu data={article.data} />
          )}
          {me.data.is_staff && <ArticleAdminMenu data={article.data} />}
          <ArticleTitleArea data={article.data} />
        </Box>
        <Divider sx={{ mx: 2, mb: 2 }} />
        {/* 本文 */}
        <ScrollBox>
          {!putArticleState.updated && (
            <TextEditor
              readOnly
              editorCore={editorCore}
              defaultValue={JSON.parse(article.data.text)}
            />
          )}
          {/* コメント */}
          <Divider sx={{ fontSize: 14, mx: 2 }}>Comments</Divider>
          <Box sx={{ px: { xs: 1, lg: 4 } }}>
            <ArticleCommentsPostArea id={id} />
            <ArticleCommentsList id={id} />
          </Box>
        </ScrollBox>
      </ContentCard>
    </>
  );
};
