// 記事作成編集Dialog用Component_プレビュー上部タイトル他情報エリア

// 外部ライブラリ
import { memo } from "react";
import { useSelector } from "react-redux";
import { Typography, Box, Chip } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

// プロジェクト内リソース
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { CustomAvatar } from "../Share/CustomAvatar";

// ***レンダリング***
export const CreateEditArticleDialogPreviewTitleArea = memo(() => {
  // 作成編集中記事状態
  const { articleParams } = useSelector((state) => state.articleState);

  // ユーザーデータ取得
  const { data, isLoading } = useQueryMeApi();

  // データ取得中は表示しない
  if (isLoading) return;

  return (
    <>
      {articleParams.is_banned && (
        <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
          <BlockIcon color="error" />
          <Typography variant="h7" color="error">
            この記事は管理者により公開が禁止されています
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={2}>
          <CustomAvatar data={data} sx={{ width: 58, height: 58 }} />
        </Box>
        <Box sx={{ textOverflow: "ellipsis" }}>
          <Typography>{`@${data.display_name}`}</Typography>
          <Typography variant="subtitle2">
            {articleParams.initial_published_at &&
              `投稿日:${articleParams.initial_published_at} | 更新日:${articleParams.last_published_at}`}
          </Typography>
        </Box>
      </Box>
      <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
        {articleParams.title}
      </Typography>
      <Box mt={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: {
              xs: "84vw",
              sm: "90vw",
              md: "65vw",
              lg: "68vw",
              xl: "70vw",
            },
            overflow: "auto",
          }}
        >
          <LocalOfferIcon
            fontSize="small"
            sx={{
              color: "base.contrastText",
              mr: 1,
            }}
          />
          {articleParams.tags.map((item) => {
            return (
              <Chip key={item} label={item} sx={{ mr: 0.2 }} size="small" />
            );
          })}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.6 }}>
          <Chip
            color="primary"
            icon={<ThumbUpOffAltIcon />}
            label="-"
            sx={{ px: 0.6, fontSize: 11 }}
            variant="outlined"
          />
          <CommentIcon sx={{ color: "base.contrastText", ml: 2.6 }} />
          <Typography variant="subtitle2" sx={{ ml: 1 }}>
            -
          </Typography>
          <StarOutlineIcon sx={{ color: "base.contrastText", ml: 2 }} />
          <Typography variant="subtitle2" sx={{ ml: 1, flexGrow: 1 }}>
            -
          </Typography>
          <StarOutlineIcon
            fontSize="large"
            color="primary"
            sx={{ opacity: 0.6 }}
          />
        </Box>
      </Box>
    </>
  );
});
