// // 共通Component_アバター

// 外部ライブラリ
import { memo } from "react";
import { Avatar } from "@mui/material";

// Avator画像無時のイニシャル表示
const initialName = (display_name) => {
  return `${display_name.slice(0, 1).toUpperCase()}${display_name
    .substr(display_name.indexOf(" ") + 1)
    .slice(0, 1)
    .toUpperCase()}`;
};

// ***レンダリング***
export const CustomAvatar = memo((props) => {
  // ***props説明***
  // data: アバター情報オブジェクト（user_avatar: アバター画像のURL, display_name: 表示名）
  // anchorref: アバターコンポーネントに紐付ける参照内容
  // onClick: アバタークリック時の実行関数
  // sx: スタイル設定
  const { data, anchorref, onClick, sx } = props;

  return (
    <Avatar ref={anchorref} src={data.user_avatar} onClick={onClick} sx={sx}>
      {initialName(data.display_name)}
    </Avatar>
  );
});
