// 記事作成編集Dialog用Component_タイトル他情報エリア_タグ選択部分

// 外部ライブラリ
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, TextField, Chip, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// プロジェクト内リソース
import { onChangeArticleParams } from "../../features/articleStateSlice";

// ***レンダリング***
export const CreateEditArticleDialogTagCloud = (props) => {
  const dispatch = useDispatch();

  // ***props説明***
  // tagList: 利用可能タグリスト
  // setTagList: タグリストセッター
  // defaultTags: 選択タグ初期値(編集用)
  // isPost: 記事作成画面用判定フラグ
  const { tagList, setTagList, defaultTags, isPost } = props;

  // 作成編集中記事状態
  const { articleParams } = useSelector((state) => state.articleState);
  // タグフィルターキーワード管理
  const [keyword, setKeyword] = useState("");

  // タグフィルターキーワード入力
  const onChangeKeyword = useCallback((event) => {
    setKeyword(event.target.value);
  }, []);

  // タグフィルターキーワード削除
  const onClickClearKeyword = useCallback(() => {
    setKeyword("");
  }, []);

  // 選択タグAll_Clear
  const onClickSelectTagsClear = useCallback(() => {
    dispatch(onChangeArticleParams({ tags: [] }));
  }, []);

  // 選択タグ初期値に戻す（編集時のみ）
  const onClickSelectTagsReset = useCallback(() => {
    dispatch(onChangeArticleParams({ tags: defaultTags }));
  }, [defaultTags]);

  // タグ絞り込み＆ソート
  const filteredAndSortedTags = () => {
    // タグの絞り込み
    const filteredTags = tagList.filter((item) => {
      const re = new RegExp(keyword, "i");
      return item.match(re);
    });
    const sortedSelectTag = filteredTags.sort((x) =>
      articleParams.tags.includes(x) ? -1 : 1
    );
    return sortedSelectTag;
  };

  // 選択タグ処理
  const handleSelectTag = useCallback(
    (value) => {
      articleParams.tags.includes(value)
        ? dispatch(
            onChangeArticleParams({
              tags: articleParams.tags.filter((x) => x !== value),
            })
          )
        : dispatch(
            onChangeArticleParams({ tags: [...articleParams.tags, value] })
          );
    },
    [articleParams]
  );

  // タグ追加
  const onClickAddTag = useCallback(() => {
    setTagList([...tagList, keyword]);
    dispatch(onChangeArticleParams({ tags: [...articleParams.tags, keyword] }));
    setKeyword("");
  }, [tagList, articleParams, keyword]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <TextField
          placeholder="Filter / Create Tag"
          value={keyword}
          variant="standard"
          fullWidth
          onChange={onChangeKeyword}
          sx={{ mr: 2 }}
          InputProps={{
            endAdornment: (
              <>
                <IconButton onClick={onClickClearKeyword}>
                  <CloseIcon fontSize="small" />
                </IconButton>
                <Button
                  disabled={!keyword}
                  onClick={onClickAddTag}
                  sx={{ maxHeight: 0, textTransform: "none" }}
                >
                  Tag追加
                </Button>
              </>
            ),
          }}
        />
        {!isPost && <Button onClick={onClickSelectTagsReset}>reset</Button>}
        <Button onClick={onClickSelectTagsClear}>clear</Button>
      </Box>
      <Box sx={{ height: 138, mt: 1, overflow: "auto" }}>
        {filteredAndSortedTags().map((item, index) => {
          return (
            <Chip
              key={index}
              label={item}
              color="primary"
              size="small"
              variant={
                articleParams.tags.includes(item) ? "contained" : "outlined"
              }
              onClick={() => handleSelectTag(item)}
              sx={{ mr: 0.4, px: 0.4, fontWeight: "normal" }}
            />
          );
        })}
      </Box>
    </>
  );
};
