// 管理者用_ADMIN/記事管理用Component_記事管理テーブル

// 外部ライブラリ
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import DetailsIcon from "@mui/icons-material/Details";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";

// プロジェクト内リソース
import { useQueryAdmArticleListApi } from "../../hooks/useQueryAdmArticleListApi";
import { handleAdminArticleDialog } from "../../features/articleStateSlice";
import { CustomDataGrid } from "../Share/CustomDataGrid";

// ***レンダリング***
export const AdminArticleTable = memo(() => {
  const dispatch = useDispatch();

  // URLからインデックスパラメータを取得
  const { index } = useParams();

  // 管理者用_記事リストデータ取得
  const { data, isLoading } = useQueryAdmArticleListApi(index);

  // カラム設定
  const columns = [
    {
      field: "check",
      headerName: "管理",
      headerAlign: "center",
      maxWidth: 52,
      minWidth: 52,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row) => {
        return (
          <IconButton
            onClick={() => dispatch(handleAdminArticleDialog(row.row.id))}
          >
            <DetailsIcon />
          </IconButton>
        );
      },
    },
    { field: "id", width: 72, align: "right" },
    {
      field: "is_published",
      headerName: "公開",
      headerAlign: "center",
      maxWidth: 52,
      minWidth: 52,
      align: "center",
      disableColumnMenu: true,
      renderCell: (row) => {
        return row.row.is_published && <CheckIcon color="secondary" />;
      },
    },
    {
      field: "is_banned",
      headerName: "禁止",
      headerAlign: "center",
      maxWidth: 52,
      minWidth: 52,
      align: "center",
      disableColumnMenu: true,
      renderCell: (row) => {
        return row.row.is_banned && <BlockIcon color="error" />;
      },
    },
    {
      field: "title",
      headerName: "タイトル",
      width: 358,
      sortable: false,
    },
    {
      field: "owner",
      headerName: "作成者",
      width: 162,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row) => {
        return row.row.owner.display_name;
      },
    },
    {
      field: "last_published_at",
      headerName: "更新日",
      width: 150,
    },
    {
      field: "initial_published_at",
      headerName: "投稿日",
      width: 150,
    },
    {
      field: "created_at",
      headerName: "作成日",
      width: 150,
    },
  ];

  return (
    <CustomDataGrid
      rows={isLoading || !data ? [] : data.results}
      columns={columns}
      loading={isLoading}
    />
  );
});
