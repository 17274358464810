// MY PAGE/プロフィール_アバター画像変更Dialog用Component_画像を選択ボタン

// 外部ライブラリ
import { memo } from "react";
import { Button, Box } from "@mui/material";

// ***レンダリング***
export const MyPageProfileAreaUploadButton = memo((props) => {
  return (
    <label htmlFor={`upload-button-${props.name}`}>
      <input
        accept="image/*,.png,.jpg,.jpeg"
        id={`upload-button-${props.name}`}
        name={props.name}
        multiple
        type="file"
        onChange={props.onChange}
        style={{ display: "none" }}
      />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button component="span" {...props}>
          {props.children}
        </Button>
      </Box>
    </label>
  );
});
