// Azure認証前表示画面

// 外部ライブラリ
import { useMsal } from "@azure/msal-react"; // Azure認証用
import { Grid, Box, Typography, Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";

// プロジェクト内リソース
import { loginRequest } from "../utils/authConfig";
import Logo from "../Images/logo.png";

// ***レンダリング***
export const UnAuth = () => {
  const { instance } = useMsal(); // MSALインスタンス

  // サインイン処理関数
  const onClickSignIn = () => {
    instance.loginPopup(loginRequest).catch((error) => console.log(error));
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography color="primary" variant="h5">
        Welcome to "Knowledge Campus".
      </Typography>
      <Box
        sx={{
          height: 162,
          width: 162,
          backgroundImage: `url(${Logo})`,
          backgroundSize: "cover",
          borderRadius: "50%",
          mx: 1,
        }}
      />
      <Button
        variant="contained"
        onClick={onClickSignIn}
        startIcon={<LoginIcon />}
        size="medium"
        sx={{ fontSize: 16, textTransform: "none", mt: 1 }}
      >
        Sign in
      </Button>
    </Grid>
  );
};
