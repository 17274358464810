//　ヘッダー用Component_検索エリア_タグ検索/検索設定Popper_キーワード検索設定部分

// 外部ライブラリ
import { memo } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";

// ***レンダリング***
export const HeaderSearchAreaSettingRadioGroup = memo((props) => {
  // ***props説明***
  // label: ラジオボタングループのラベル
  // itemList: ラジオボタンの項目リスト。各項目は'name'と'value'プロパティを持つ
  // value: 現在選択されているラジオボタンの値
  // onChange: ラジオボタン選択時に発生するイベントハンドラ
  const { label, itemList, value, onChange } = props;

  return (
    <FormControl>
      <FormLabel sx={{ fontSize: 14 }}>{label}</FormLabel>
      <RadioGroup row onChange={onChange} value={value}>
        {itemList.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              label={<Typography variant="body2">{item.name}</Typography>}
              value={item.value}
              control={<Radio />}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
});
