//　利用者データ取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getMe = async () => {
  const { data } = await client.get("/me/");
  return data;
};

export const useQueryMeApi = () => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["me"],
    queryFn: getMe,
    cacheTime: 10000,
    staleTime: Infinity,
  });
};
