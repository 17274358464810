//　記事検索結果リスト取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getArticleList = async (params) => {
  // クエリパラメータ:各検索条件・取得記事数
  const { data } = await client.get("/article/", {
    params: {
      ...params,
      page_size: params.page_size ? params.page_size : 20,
    },
  });
  return data;
};

export const useQuerySearchArticleApi = (params) => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // refetchOnMount: マウント都度データを再取得する設定
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["article_list", params],
    queryFn: () => getArticleList(params),
    refetchOnMount: true,
    cacheTime: 30000,
    staleTime: 0,
  });
};
