// ページ全体レイアウト
// 認証前:サインインページを表示する
// 認証後:ヘッダー・サイドメニュー・AuthRoutesに応じたコンテナを表示する

// 外部ライブラリ
import { useEffect } from "react";
import { Outlet } from "react-router-dom"; // コンテナ表示切替部分
import {
  InteractionType,
  InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser"; // Azure認証用
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react"; // Azure認証用
import { Box } from "@mui/material";

// プロジェクト内リソース
import { loginRequest } from "./utils/authConfig";
import { Header } from "./Components/PageLayout/Header";
import { UnAuth } from "./Containers/UnAuth";
import Logo from "./Images/logo.png";
import { SideMenu } from "./Components/PageLayout/SideMenu";
import { CreateArticleDialog } from "./Components/ArticleDialog/CreateArticleDialog";
import { PostArticleCheckDialog } from "./Components/ArticleDialog/PostArticleCheckDialog";
import { ArticleOwnerDialog } from "./Components/ArticleDialog/ArticleOwnerDialog";

// ***レンダリング***
export const PageLayout = () => {
  // 認証確認処理
  // ポップアップ認証エラー時にリダイレクト認証へフォールバック
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  );
  useEffect(() => {
    if (error && error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, loginRequest).catch((err) => {
        if (
          err instanceof BrowserAuthError &&
          (err.errorCode === "popup_window_error" ||
            err.errorCode === "empty_window_error")
        ) {
          login(InteractionType.Redirect, loginRequest);
        }
      });
    }
  }, [error]);

  return (
    <>
      {/* 非認証時 */}
      <UnauthenticatedTemplate>
        <UnAuth />
      </UnauthenticatedTemplate>
      {/* 認証時 */}
      <AuthenticatedTemplate>
        <CreateArticleDialog />
        <PostArticleCheckDialog />
        <ArticleOwnerDialog />
        <Header />
        <Box sx={{ display: "flex" }}>
          <SideMenu />
          <Box
            sx={{
              pt: 10,
              px: 1,
              pb: 1,
              height: "100vh",
              overflow: "hidden",
              position: "relative",
              flexGrow: 1,
              bgcolor: "base.bg",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: 52,
                opacity: 0.32,
              }}
            >
              <img src={Logo} height={462} width={462} />
            </Box>
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
                height: "100%",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </AuthenticatedTemplate>
    </>
  );
};
