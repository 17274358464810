//　PageLayout用Component_ナビゲーションサイドメニュー

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// プロジェクト内リソース
import { handleDrawer } from "../../features/drawerSlice";
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { useQueryTagApi } from "../../hooks/useQueryTag";
import { useQueryMessageListApi } from "../../hooks/useQueryMessageListApi";
import { SideMenuSubMenuArea } from "./SideMenuComponents/SideMenuSubMenuArea";
import { SideMenuMainMenuArea } from "./SideMenuComponents/SideMenuMainMenuArea";

// ***レンダリング***
export const SideMenu = memo(() => {
  const dispatch = useDispatch();

  // ドロワー表示状態(mobileのみ)
  const { drawer } = useSelector((state) => state.drawer);

  const me = useQueryMeApi(); // ユーザーデータ取得
  const tag = useQueryTagApi(); // タグデータ取得
  const message = useQueryMessageListApi(); // あなたへのお知らせデータ取得

  // 必要データローディング状態
  const loading = me.isLoading || tag.isLoading || message.isLoading;

  return (
    <>
      {/* 大画面時 */}
      <Box
        sx={{
          bgcolor: "drawer.main",
          height: "100vh",
          minWidth: 86,
          overflow: "hidden",
          pt: 9,
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <SideMenuMainMenuArea data={me.data} loading={loading} />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <SideMenuSubMenuArea loading={loading} />
      </Box>
      {/* 画面縮小時Drawer */}
      <Drawer
        sx={{ zIndex: 1300, display: { md: "none" } }}
        onClose={() => dispatch(handleDrawer())}
        open={drawer}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              minWidth: 92,
              bgcolor: "drawer.main",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <IconButton
              onClick={() => dispatch(handleDrawer())}
              color="base"
              size="large"
              sx={{ ml: 1, mt: 1, mb: 0.7 }}
            >
              <NavigateBeforeIcon fontSize="large" />
            </IconButton>
            <Divider sx={{ bgcolor: "drawer.divider" }} />
            <SideMenuMainMenuArea data={me.data} loading={loading} />
          </Box>
          <SideMenuSubMenuArea loading={loading} />
        </Box>
      </Drawer>
    </>
  );
});
