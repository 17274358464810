// 管理者用_アクセスログ取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getAccessLog = async (page) => {
  // クエリパラメータ:ページ番号
  const { data } = await client.get("/adm_access_log/", {
    params: { page: page },
  });
  return data;
};

export const useQueryAccessLogApi = (page) => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["access_log", page],
    queryFn: () => getAccessLog(page),
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};
