// Reduxストア

// 外部ライブラリ
import { configureStore } from "@reduxjs/toolkit";

// プロジェクト内リソース
import accessLogReducer from "./accessLogSlice";
import articleSearchParamsReducer from "./articleSearchParamsSlice";
import articleStateReducer from "./articleStateSlice";
import avatarStateReducer from "./avatarStateSlice";
import drawerReducer from "./drawerSlice";
import messageStateReducer from "./messageStateSlice";
import notificationStateReducer from "./notificationStateSlice";
import articleOwnerReducer from "./articleOwnerSlice";

export const store = configureStore({
  reducer: {
    accessLog: accessLogReducer, // 管理者用_アクセスログ表示状態管理
    articleSearchParams: articleSearchParamsReducer, // 記事検索条件状態管理
    articleState: articleStateReducer, // 記事投稿・更新・表示状態管理
    avatarState: avatarStateReducer, // アバター更新状態管理
    drawer: drawerReducer, // 左側ドロワー表示状態管理(mobile用)
    messageState: messageStateReducer, // あなたへのお知らせ表示状態管理
    notificationState: notificationStateReducer, // お知らせ投稿・更新・表示状態管理
    articleOwner: articleOwnerReducer, // 記事・コメント投稿者表示状態管理
  },
});
