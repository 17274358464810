// 共通Component_ページネーション

// 外部ライブラリ
import { memo, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Pagination, Box, Typography } from "@mui/material";

// ***レンダリング***
export const CustomPagination = memo((props) => {
  const url = useLocation();
  const navigate = useNavigate();

  // ***props説明***
  // count: レコード総数
  // loading: データローディング状態
  // pageSize: 1ページあたりの表示レコード数
  const { count, loading, pageSize } = props;

  // URLからインデックスパラメータを取得
  const { index } = useParams();

  // ページ変更時の処理関数
  const onChangePagination = useCallback(
    async (event, value) => {
      navigate(
        `${url.pathname.substring(
          0,
          url.pathname.indexOf("index")
        )}index${value}${url.search}`
      );
    },
    [url]
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Pagination
        size="small"
        count={Math.ceil(count / (pageSize ? pageSize : 20))}
        onChange={onChangePagination}
        page={parseInt(index)}
        showFirstButton
        showLastButton
        disabled={loading}
      />
      <Typography variant="subtitle1" sx={{ ml: 4 }}>{`Total：${
        count ? count : ""
      }`}</Typography>
    </Box>
  );
});
