// お知らせ投稿・更新・表示状態管理
import { createSlice } from "@reduxjs/toolkit";
import { today } from "../utils/datetime";

// お知らせ投稿・編集内容初期設定
const defaultParamsState = {
  title: "",
  start_date: null,
  end_date: null,
  is_active: true,
};

// APIリクエスト状態初期設定
const defaultFetchState = {
  dialog: false, // APIリクエスト確認Dialog状態
  updating: false, // APIリクエスト処理中
  updated: false, // APIリクスト完了
};

// 初期値
const initialState = {
  // ***Dialog表示状態管理***
  notificationDialog: { id: "", dialog: false }, // お知らせ
  adminNotificationDialog: { id: "", dialog: false }, // 管理者画面用_お知らせ
  createNotificationDialog: false, // 管理者用_新規お知らせ作成
  editNotificationDialog: false, // 管理者用_お知らせ編集
  // ***作成中・編集中記事状態管理***
  notificationParams: defaultParamsState, // 管理者用_作成中・編集中お知らせ状態
  createNotificationPreviewTab: 0, // 管理者用_作成中お知らせプレビュー切替
  editNotificationPreviewTab: 0, // 管理者用_編集中お知らせプレビュー切替
  // ***APIリクエスト状態管理***
  postNotificationState: defaultFetchState, // 管理者用_新規お知らせ投稿
  putNotificationState: defaultFetchState, // 管理者用_お知らせ更新
  patchIsActiveNotificationState: defaultFetchState, // 管理者用_お知らせ公開状態更新
  deleteNotificationState: defaultFetchState, // 管理者用_お知らせ削除
};

const notificationStateSlice = createSlice({
  name: "notificationState",
  initialState,
  reducers: {
    // ***Dialog開閉関数***
    // 管理者用_新規お知らせ作成開く
    onClickCreateNotificationDialogOpen: (state) => {
      // 作成中お知らせ状態の初期化・開始日を今日に設定
      state.notificationParams = {
        ...defaultParamsState,
        start_date: today,
      };
      // Dialog開く
      state.createNotificationDialog = true;
    },

    // 管理者用_コピー新規開く
    onClickCopyCreateNotificationDialogOpen: (state, actions) => {
      // コピー対象のお知らせ内容を作成中お知らせ状態にセット・開始日を今日に設定
      state.notificationParams = {
        ...actions.payload,
        start_date: today,
        end_date: null,
      };
      // Dialog開く
      state.createNotificationDialog = true;
    },

    //　管理者用_新規お知らせ作成閉じる
    onClickCreateNotificationDialogClose: (state) => {
      // Dialog閉じる
      state.createNotificationDialog = false;
      // プレビュータブ状態初期化
      state.createNotificationPreviewTab = 0;
    },

    // 管理者用_お知らせ編集開閉
    handleEditNotificationDialog: (state, actions) => {
      // Dialog開く時: 対象お知らせ内容を編集中お知らせ状態にセット
      // Dialog閉じる時: プレビュータブ状態初期化
      if (!state.editNotificationDialog) {
        state.notificationParams = actions.payload;
      } else {
        state.editNotificationPreviewTab = 0;
      }
      // Dialog開閉
      state.editNotificationDialog = !state.editNotificationDialog;
    },

    // 管理者用_公開・非公開切替確認開閉
    handlePatchIsActiveNotificationDialog: (state) => {
      state.patchIsActiveNotificationState = {
        ...state.patchIsActiveNotificationState,
        dialog: !state.patchIsActiveNotificationState.dialog,
      };
    },
    //  お知らせ参照開閉
    handleNotificationDialog: (state, actions) => {
      // Dialogを開く時のみ対象お知らせIDを取得
      if (!state.notificationDialog.dialog) {
        state.notificationDialog = { id: actions.payload, dialog: true };
      } else {
        state.notificationDialog = {
          ...state.notificationDialog,
          dialog: false,
        };
      }
    },

    //  管理者用_お知らせ参照開閉
    handleAdminNotificationDialog: (state, actions) => {
      // Dialogを開く時のみ対象お知らせIDを取得
      if (!state.adminNotificationDialog.dialog) {
        state.adminNotificationDialog = { id: actions.payload, dialog: true };
      } else {
        state.adminNotificationDialog = {
          ...state.adminNotificationDialog,
          dialog: false,
        };
      }
    },

    // ***作成中・編集中記事状態管理***
    // 管理者用_作成中・編集中お知らせ状態変更
    onChangeNotificationParams: (state, actions) => {
      state.notificationParams = {
        ...state.notificationParams,
        ...actions.payload,
      };
    },

    // 管理者用_新規お知らせ作成中プレビュー切替
    handleCreateNotificationPreviewTab: (state, actions) => {
      state.createNotificationPreviewTab = actions.payload;
    },

    // 管理者用_お知らせ編集中プレビュー切替
    handleEditNotificationPreviewTab: (state, actions) => {
      state.editNotificationPreviewTab = actions.payload;
    },

    // 管理者用_作成中・編集中お知らせ状態初期化
    onClickClearNotificationParams: (state) => {
      state.notificationParams = defaultParamsState;
    },

    // ***APIリクエスト状態管理***
    // 管理者用_新規お知らせ投稿
    handlePostNotificationState: (state, actions) => {
      state.postNotificationState = {
        ...state.postNotificationState,
        ...actions.payload,
      };
    },

    //  管理者用_お知らせ更新
    handlePutNotificationState: (state, actions) => {
      state.putNotificationState = {
        ...state.putNotificationState,
        ...actions.payload,
      };
    },

    //　管理者用_お知らせ公開状態更新
    handlePatchIsActiveNotificationState: (state, actions) => {
      state.patchIsActiveNotificationState = {
        ...state.patchIsActiveNotificationState,
        ...actions.payload,
      };
    },

    // 管理者用_お知らせ削除
    handleDeleteNotificationState: (state, actions) => {
      state.deleteNotificationState = {
        ...state.deleteNotificationState,
        ...actions.payload,
      };
    },
  },
});

export const {
  onChangeNotificationParams,
  handleNotificationDialog,
  handleAdminNotificationDialog,
  onClickCreateNotificationDialogOpen,
  onClickCopyCreateNotificationDialogOpen,
  onClickCreateNotificationDialogClose,
  handleEditNotificationDialog,
  handlePatchIsActiveNotificationDialog,
  handlePostNotificationState,
  handlePutNotificationState,
  handlePatchIsActiveNotificationState,
  handleDeleteNotificationState,
  handleCreateNotificationPreviewTab,
  handleEditNotificationPreviewTab,
  onClickClearNotificationParams,
} = notificationStateSlice.actions;

export default notificationStateSlice.reducer;
