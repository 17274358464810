// 共通Component_リッチテキストエディタ

// 外部ライブラリ
import { memo, useCallback } from "react";
import { Box } from "@mui/material";
import { createReactEditorJS } from "react-editor-js";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Code from "@editorjs/code";
import Image from "@editorjs/image";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import AttachesTool from "@editorjs/attaches";

// プロジェクト内リソース
import { client } from "../../utils/authClient";
import "../../Styles/editor.css";

// 各ブロックタイプ設定
const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  list: List,
  code: Code,
  image: {
    class: Image,
    inlineToolbar: false,
    config: {
      stretched: false,
      uploader: {
        uploadByFile(file) {
          const formData = new FormData();
          formData.append("image", file);
          formData.append("image_name", "article_image");
          return client.post("/my_image/", formData).then((res) => {
            return {
              success: 1,
              file: { url: res.data.image },
            };
          });
        },
      },
    },
  },
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  attaches: {
    class: AttachesTool,
    config: {
      types:
        "text/plain,\
        text/csv,\
        application/pdf,\
        application/msword,\
        application/vnd.openxmlformats-officedocument.wordprocessingml.document,\
        application/vnd.ms-excel,\
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\
        application/vnd.ms-powerpoint,\
        application/vnd.openxmlformats-officedocument.presentationml.presentation",
      uploader: {
        uploadByFile(file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("file_name", "article_file");
          return client.post("/my_uploadedfile/", formData).then((res) => {
            const withParams = res.data.file.split(".").pop();
            return {
              success: 1,
              file: {
                url: res.data.file,
                extension: withParams.substring(0, withParams.indexOf("?")),
              },
            };
          });
        },
      },
    },
  },
};

// ツールバー表示言語設定
const i18n = {
  messages: {
    toolNames: {
      Text: "テキスト",
      Heading: "タイトル",
      List: "リスト",
      Checklist: "チェックリスト",
      Quote: "引用",
      Delimiter: "区切り線",
      Table: "表",
      Link: "リンク",
      Image: "画像",
      Attachment: "添付ファイル",
    },
  },
};

const ReactEditorJS = createReactEditorJS();

// ***レンダリング***
export const TextEditor = memo((props) => {
  // ***props説明***
  // editorCore: エディタ内容の格納用refオブジェクト
  // defaultValue: エディタ初期表示内容
  // readOnly: 読み取り専用設定
  const { editorCore, defaultValue, readOnly } = props;

  // エディタ初期化時処理
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  return (
    <Box sx={{ minHeight: "100%" }}>
      <ReactEditorJS
        placeholder="ここに本文を入力して下さい"
        onInitialize={handleInitialize}
        defaultValue={defaultValue}
        tools={EDITOR_JS_TOOLS}
        i18n={i18n}
        readOnly={readOnly}
        minHeight={62}
        blocks={{ stretched: false }}
      />
    </Box>
  );
});
