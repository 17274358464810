// お知らせ参照Dialog用Component_管理者用メニュー

// 外部ライブラリ
import { memo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Box,
  Chip,
  Button,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// プロジェクト内リソース
import {
  handleDeleteNotificationState,
  handleEditNotificationDialog,
  handlePatchIsActiveNotificationDialog,
  onClickCopyCreateNotificationDialogOpen,
} from "../../features/notificationStateSlice";
import { PatchIsActiveNotificationCheckDialog } from "./PatchIsActiveNotificationCheckDialog";
import { DeleteNotificationCheckDialog } from "./DeleteNotificationCheckDialog";

// ***レンダリング***
export const NotificationDialogAdminMenu = memo(({ data }) => {
  // ***props説明***
  // data: お知らせ単体内容

  const dispatch = useDispatch();

  // 公開状態切替用一時ステート保持
  const [isActive, setIsActive] = useState(data.is_active);

  // 公開状態切替用関数
  const onChangeNotificationIsActive = useCallback(() => {
    setIsActive(!isActive);
    dispatch(handlePatchIsActiveNotificationDialog());
  }, [isActive]);

  return (
    <>
      <PatchIsActiveNotificationCheckDialog
        params={{ id: data.id, is_active: !data.is_active }}
        onChangeNotificationIsActive={onChangeNotificationIsActive}
      />
      <DeleteNotificationCheckDialog id={data.id} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Chip label="管理者操作" variant="outlined" />
        <FormControlLabel
          sx={{ width: 108, ml: 0.6 }}
          control={
            <Switch
              checked={isActive}
              onChange={onChangeNotificationIsActive}
            />
          }
          label={<Typography>{data.is_active ? "公開" : "非公開"}</Typography>}
        />

        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => dispatch(handleEditNotificationDialog(data))}
          sx={{ ml: 1.6 }}
        >
          編集
        </Button>
        <Button
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={() =>
            dispatch(handleDeleteNotificationState({ dialog: true }))
          }
          sx={{ ml: 1.6 }}
          color="error"
        >
          削除
        </Button>
        <Button
          variant="contained"
          startIcon={<ContentCopyIcon />}
          onClick={() =>
            dispatch(onClickCopyCreateNotificationDialogOpen(data))
          }
          sx={{
            display: { xs: "none", sm: "flex" },
            ml: 1.6,
          }}
        >
          複写新規作成
        </Button>
      </Box>
      <Divider sx={{ mt: 1, mb: 1.6 }} />
    </>
  );
});
