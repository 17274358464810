// お知らせ/ナレッジ共通Component_新規作成・編集Dialog_上部メニュー

// 外部ライブラリ
import { memo } from "react";
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  Zoom,
} from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";

// タブ表示設定
const tabItems = [
  { title: "編集画面", icon: <EditIcon /> },
  { title: "プレビュー画面", icon: <PreviewIcon /> },
];

// ***レンダリング***
export const CreateEditArticleNotificationDialogMainMenu = memo((props) => {
  // ***props説明***
  // label: Dialogタイトル
  // tab: 現在のアクティブタブ
  // handleTab: タブ変更関数
  // handleFetchDialog: 投稿・更新ボタンクリック時の実行関数
  // disableButton: 投稿・更新ボタン無効状態
  // isPost: 投稿/更新表示切替
  const { label, tab, handleTab, handleFetchDialog, disableButton, isPost } =
    props;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
          }}
        >
          <DriveFileRenameOutlineIcon />
          <Typography sx={{ ml: 1, mr: { xs: 4, md: 6 } }}>{label}</Typography>
        </Box>
        <Tabs value={tab} onChange={handleTab}>
          {tabItems.map((item, index) => {
            return (
              <Tooltip
                title={item.title}
                TransitionComponent={Zoom}
                key={index}
              >
                <Tab icon={item.icon} />
              </Tooltip>
            );
          })}
        </Tabs>
        <Button
          variant="contained"
          size="medium"
          onClick={handleFetchDialog}
          disabled={disableButton}
          sx={{ ml: { xs: 2, md: 4 } }}
          startIcon={isPost ? <SendIcon /> : <ChangeCircleIcon />}
        >
          {isPost ? "投稿" : "更新"}
        </Button>
      </Box>
    </>
  );
});
