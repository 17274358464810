// Material-Uiテーマ設定(全体に適応されるデザインの定義)

import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  // フォント設定
  typography: {
    fontFamily: "Lato,Kosugi Maru,Arial,sans-serif",
  },
  // ツールバーサイズ
  mixins: {
    toolbar: {
      height: 72,
    },
  },
  // テーマカラー定義
  palette: {
    // プライマリーカラー
    primary: {
      main: "#50ace4",
      light: "rgba(80,172,228,0.1)",
      contrastText: "#feffff",
    },
    // セカンダリーカラー
    secondary: {
      main: "#ffb42c",
      light: "rgba(255,180,44,0.2)",
      contrastText: "#feffff",
    },
    // 背景色と基本テキストカラー
    base: {
      bg: "#f5f5f5",
      main: "#feffff",
      contrastText: "#646464",
    },
    // エラー表示用カラー
    error: {
      main: "#f06c64",
      contrastText: "#feffff",
    },
    // ヘッダー用カラー
    header: {
      main: "#feffff",
    },
    // サイドドロワー用カラー
    drawer: {
      main: "rgba(80,172,228,0.74)",
      hover: "rgba(80,172,228,0.3)",
      divider: "rgba(255,255,255,0.48)",
    },
  },
  // Material-UI特定コンポーネントに対する初期スタイル変更
  components: {
    MuiTypography: {
      styleOverrides: {
        caption: { color: "#757575" },
        subtitle1: { color: "#757575" },
        subtitle2: { color: "#757575" },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#ffb42c",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
        color: "primary",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
  },
});
