// 記事編集Dialog

// 外部ライブラリ
import { memo, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";

// プロジェクト内リソース
import {
  handleEditArticleDialog,
  handleEditArticlePreviewTab,
  handlePutArticleState,
  onChangeArticleParams,
} from "../../features/articleStateSlice";
import { TextEditor } from "../Share/TextEditor";
import { ArticleNotificationDialogTemplate } from "../Share/ArticleNotificationDialogTemplate ";
import { CreateEditArticleNotificationDialogMainMenu } from "../Share/CreateEditArticleNotificationDialogMainMenu";
import { CreateEditArticleDialogSwitchInputPreview } from "./CreateEditArticleDialogSwitchInputPreview";
import { CreateEditArticleDialogPreviewText } from "./CreateEditArticleDialogPreviewText";

// ***レンダリング***
export const EditArticleDialog = memo(({ defaultTags }) => {
  // ***props説明***
  // defaultTags:その記事の編集前タグリスト

  const dispatch = useDispatch();

  // 記事本文状態
  const editorCore = useRef(null);

  // Dialog状態・作成中記事状態・作成中記事プレビュータブ選択状態
  const { editArticleDialog, articleParams, editArticlePreviewTab } =
    useSelector((state) => state.articleState);

  // タイトル文字数制限
  const capsLimit = articleParams.title.length > 180;

  // 投稿・更新ボタン無効条件
  const disableButton =
    !articleParams.title || !articleParams.tags.length || capsLimit;

  // プレビューEditor本文
  const [previewText, setPreviewText] = useState();

  // プレビュー・編集選択Tab
  const handlePreviewTab = useCallback(
    async (event, value) => {
      if (!editArticlePreviewTab) {
        const saveCore = await editorCore.current.save();
        setPreviewText(saveCore);
        dispatch(onChangeArticleParams({ text: JSON.stringify(saveCore) }));
      }
      dispatch(handleEditArticlePreviewTab(value));
    },
    [editArticlePreviewTab, editorCore]
  );

  // editor編集内容をグローバルステートへ反映
  const onClickPutArticleCheckDialogOpen = useCallback(async () => {
    if (!editArticlePreviewTab) {
      const savedData = await editorCore.current.save();
      dispatch(onChangeArticleParams({ text: JSON.stringify(savedData) }));
    }
    dispatch(handlePutArticleState({ dialog: true }));
  }, [editArticlePreviewTab, editorCore]);

  return (
    <>
      <ArticleNotificationDialogTemplate
        open={editArticleDialog}
        onClickClose={() => dispatch(handleEditArticleDialog())}
        titleContent={
          <>
            <CreateEditArticleNotificationDialogMainMenu
              label="記事編集"
              handleFetchDialog={onClickPutArticleCheckDialogOpen}
              tab={editArticlePreviewTab}
              handleTab={handlePreviewTab}
              disableButton={disableButton}
            />
            <Divider sx={{ mb: 2 }} />
            <CreateEditArticleDialogSwitchInputPreview
              previewTab={editArticlePreviewTab}
              defaultTags={defaultTags}
            />
            <Divider sx={{ mt: 2 }} />
          </>
        }
        bodyContent={
          editArticlePreviewTab ? (
            <CreateEditArticleDialogPreviewText previewText={previewText} />
          ) : (
            <TextEditor
              defaultValue={
                articleParams.text && JSON.parse(articleParams.text)
              }
              editorCore={editorCore}
            />
          )
        }
      />
    </>
  );
});
