// あなたへのお知らせ表示状態管理
import { createSlice } from "@reduxjs/toolkit";

// 初期値
const initialState = {
  messageDialog: { id: "", dialog: false },
};

const messageStateSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    // あなたへのお知らせ単体表示Dialog開閉関数
    handleMessageDialog: (state, actions) => {
      // Dialogを開く時のみ対象お知らせIDを取得
      if (!state.messageDialog.dialog) {
        state.messageDialog = { id: actions.payload, dialog: true };
      } else {
        state.messageDialog = {
          ...state.messageDialog,
          dialog: false,
        };
      }
    },
  },
});

export const { handleMessageDialog } = messageStateSlice.actions;

export default messageStateSlice.reducer;
