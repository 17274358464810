// MY PAGE/自分の記事管理

// 外部ライブラリ
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";

// プロジェクト内リソース
import { useQueryMyArticleListApi } from "../hooks/useQueryMyArticleListApi";
import { MyPageManageMyArticleTable } from "../Components/MyPage/MyPageManageMyArticleTable";
import { CustomPagination } from "../Components/Share/CustomPagination";
import { ContentCard } from "../Components/Share/ContentCard";
import { MyPageMyArticleDialog } from "../Components/MyPage/MyPageMyArticleDialog";
import { ScrollBox } from "../Components/Share/ScrollBox";

// ***レンダリング***
export const MyPageManageMyArticle = () => {
  // URLからインデックスパラメータを取得
  const { index } = useParams();

  // 自分の記事リストデータ取得
  const { data, isLoading } = useQueryMyArticleListApi(index);

  return (
    <>
      {/* 自分の記事単体Dialog */}
      <MyPageMyArticleDialog />
      {/* コンテナ部分 */}
      <ContentCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
            mb: 2,
          }}
        >
          <FeedIcon />
          <Typography variant="h7" sx={{ ml: 1 }}>
            記事管理
          </Typography>
        </Box>
        <ScrollBox>
          {/* 自分の記事管理テーブル */}
          <MyPageManageMyArticleTable />
        </ScrollBox>
        <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
          <CustomPagination
            count={isLoading || !data ? null : data.count}
            loading={isLoading}
          />
        </Box>
      </ContentCard>
    </>
  );
};
