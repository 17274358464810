//　あなたへのお知らせ単体取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getMessage = async (id) => {
  const { data } = await client.get(`/message/${id}`);
  return data;
};

export const useQueryMessageApi = (id) => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["message", id],
    queryFn: () => id && getMessage(id),
    cacheTime: 0,
    staleTime: 0,
  });
};
