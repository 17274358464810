// 管理者用_アクセスログ表示状態管理
import { createSlice } from "@reduxjs/toolkit";

// 初期値
const initialState = {
  accessLogDialog: false, // Dialog状態
  accessLogId: null, // 対象アクセスログID
};

const accessLogSlice = createSlice({
  name: "accessLog",
  initialState,
  reducers: {
    // アクセスログDialog開閉関数
    handleAccessLogDialog: (state, actions) => {
      // Dialogを開く時のみ対象アクセスログIDを取得
      if (!state.accessLogDialog) {
        state.accessLogId = actions.payload;
      }
      // Dialog開閉
      state.accessLogDialog = !state.accessLogDialog;
    },
  },
});

export const { handleAccessLogDialog } = accessLogSlice.actions;

export default accessLogSlice.reducer;
