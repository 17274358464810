// 管理者用_アクセスログ詳細取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";
// プロジェクト内リソース
import { client } from "../utils/authClient";

const getAccessLogDetails = async (id) => {
  if (id) {
    const { data } = await client.get(`/adm_access_log/${id}/`);
    return data;
  }
};

export const useQueryAccessLogDetailsApi = (id) => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["access_log_details", id],
    queryFn: () => getAccessLogDetails(id),
    cacheTime: 0,
    staleTime: 0,
  });
};
