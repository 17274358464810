// アバター画像更新用のState・APIリクエスト関数(useMutation)

// 外部ライブラリ
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";
import { handlePutAvatarState } from "../features/avatarStateSlice";

export const useMutateAvatarApi = () => {
  const dispatch = useDispatch();
  const [newAvatar, setNewAvatar] = useState([]);
  const { putAvatarState } = useSelector((state) => state.avatarState);

  // 新たに選択された画像をuseStateで保持
  const onChangeAddImage = useCallback((event) => {
    setNewAvatar(event.target.files);
  }, []);

  //  アバター更新(PUT)
  const putAvatarMutation = useMutation(
    async (id) => {
      const formData = await new FormData();
      await formData.append("image", newAvatar[0]);
      // リクエストボディ:ユーザーID・画像データ
      client.put(`/my_avatar/${id}/`, formData);
    },
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => {
        dispatch(handlePutAvatarState({ ...putAvatarState, updating: true }));
      },
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(
          handlePutAvatarState({
            ...putAvatarState,
            updating: false,
            updated: true,
          })
        );
      },
    }
  );

  return { newAvatar, setNewAvatar, onChangeAddImage, putAvatarMutation };
};
