// 管理者用_ADMIN/アクセスログ用Component_アクセスログ単体参照Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// プロジェクト内リソース
import { handleAccessLogDialog } from "../../features/accessLogSlice";
import { useQueryAccessLogDetailsApi } from "../../hooks/useQueryAccessLogDetailsApi";
import { LoadingCircular } from "../Share/LoadingCircular";

// ***レンダリング***
export const AdminAccessLogDetailsDialog = memo(() => {
  const dispatch = useDispatch();

  // Dialog状態・アクセスログID
  const { accessLogDialog, accessLogId } = useSelector(
    (state) => state.accessLog
  );

  // アクセスログ単体データ
  const { data } = useQueryAccessLogDetailsApi(accessLogId);

  return (
    <Dialog
      open={accessLogDialog}
      onClose={() => dispatch(handleAccessLogDialog())}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => dispatch(handleAccessLogDialog())}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "80vh" }}>
        {data ? (
          <>
            <Typography>{`id:${data.id}`}</Typography>
            <Typography>{`oid:${data.oid}`}</Typography>
            <Typography>{`accessed_at:${data.accessed_at}`}</Typography>
            <Typography>{`email:${data.email}`}</Typography>
            <Typography>{`origin:${data.origin}`}</Typography>
            <Typography>{`remote_address:${data.remote_address}`}</Typography>
            <Typography>{`request_path:${data.request_path}`}</Typography>
            <Typography>{`request_body:${data.request_body}`}</Typography>
            <Typography>{`request_method:${data.request_method}`}</Typography>
            <Typography>{`response_status:${data.response_status}`}</Typography>
            <Divider>response_body</Divider>
            <Typography>{data.response_body}</Typography>
          </>
        ) : (
          <LoadingCircular />
        )}
      </DialogContent>
    </Dialog>
  );
});
