// 記事参照Dialog

// 外部ライブラリ
import { memo, useRef } from "react";
import { Box, Divider } from "@mui/material";

// プロジェクト内リソース
import { ArticleCommentsPostArea } from "../Article/ArticleCommentsPostArea";
import { ArticleCommentsList } from "../Article/ArticleCommentsList";
import { EditArticleCommentDialog } from "../Article/EditArticleCommentDialog";
import { DeleteArticleCommentCheckDialog } from "../Article/DeleteArticleCommentCheckDialog";
import { CreateReplyArticleCommentDialog } from "../Article/CreateReplyArticleCommentDialog";
import { ArticleMyArticleMenu } from "../Article/ArticleMyArticleMenu";
import { DeleteArticleCheckDialog } from "./DeleteArticleCheckDialog";
import { ArticleAdminMenu } from "../Article/ArticleAdminMenu";
import { ArticleTitleArea } from "../Article/ArticleTitleArea";
import { TextEditor } from "../Share/TextEditor";
import { ArticleNotificationDialogTemplate } from "../Share/ArticleNotificationDialogTemplate ";
import { LoadingCircular } from "../Share/LoadingCircular";

// ***レンダリング***
export const ArticleDialog = memo((props) => {
  // ***props説明***
  // open:Dialog状態
  // onClickClose: Dialog閉じる関数
  // data: 記事単体データ
  // isAdmin: 管理者画面用判定フラグ
  const { open, onClickClose, data, isAdmin } = props;

  // 記事本文状態
  const editorCore = useRef(null);

  return (
    <>
      {data && (
        <>
          {/* 記事削除確認Dialog */}
          <DeleteArticleCheckDialog id={data.id} />
          {/* 返信コメント作成Dialog */}
          <CreateReplyArticleCommentDialog id={data.id} />
          {/* コメント編集Dialog */}
          <EditArticleCommentDialog id={data.id} />
          {/* コメント削除確認Dialog */}
          <DeleteArticleCommentCheckDialog />
        </>
      )}
      {/* 記事参照Dialog部分 */}
      <ArticleNotificationDialogTemplate
        open={open}
        onClickClose={onClickClose}
        titleContent={
          data && (
            <>
              {isAdmin ? (
                <ArticleAdminMenu data={data} />
              ) : (
                <ArticleMyArticleMenu data={data} />
              )}
              <ArticleTitleArea data={data} />
              <Divider sx={{ mt: 1 }} />
            </>
          )
        }
        bodyContent={
          data ? (
            <>
              <TextEditor
                readOnly
                editorCore={editorCore}
                defaultValue={data && JSON.parse(data.text)}
              />
              <Divider sx={{ fontSize: 14, mx: 2 }}>Comments</Divider>
              <Box sx={{ px: { xs: 1, lg: 4 } }}>
                <ArticleCommentsPostArea id={data.id} />
                <ArticleCommentsList id={data.id} />
              </Box>
            </>
          ) : (
            <LoadingCircular />
          )
        }
      />
    </>
  );
});
