//　あなたへのお知らせリスト取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getMessageList = async () => {
  // クエリパラメータ:取得記事数
  const { data } = await client.get("/message/", {
    params: {
      page_size: 50,
    },
  });
  return data;
};

export const useQueryMessageListApi = () => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["message_list"],
    queryFn: getMessageList,
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};
