// 管理者用_お知らせ削除確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import {
  handleAdminNotificationDialog,
  handleDeleteNotificationState,
  handleNotificationDialog,
} from "../../features/notificationStateSlice";
import { useMutateNotificationApi } from "../../hooks/useMutateNotificationApi";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const DeleteNotificationCheckDialog = memo(({ id }) => {
  // ***props説明***
  // id: 対象記事ID

  const dispatch = useDispatch();

  const {
    deleteNotificationState, // お知らせ削除APIリクエスト状態
    notificationDialog, // お知らせ参照Dialog状態
    adminNotificationDialog, // 管理画面用お知らせDialog状態
  } = useSelector((state) => state.notificationState);

  // お知らせ削除APIリクエスト関数
  const { deleteNotificationMutation } = useMutateNotificationApi();

  return (
    <>
      <UpdateDbDialog
        open={deleteNotificationState.dialog}
        onClickCloseDialog={() =>
          dispatch(handleDeleteNotificationState({ dialog: false }))
        }
        content={
          <Typography color="error">このお知らせを削除します</Typography>
        }
        onClickUpdate={() => deleteNotificationMutation.mutate(id)}
        updating={deleteNotificationState.updating}
        updated={deleteNotificationState.updated}
        onClickCheckUpdated={() => {
          dispatch(
            handleDeleteNotificationState({ dialog: false, updated: false })
          ),
            notificationDialog.dialog && dispatch(handleNotificationDialog());
          adminNotificationDialog.dialog &&
            dispatch(handleAdminNotificationDialog());
        }}
      />
    </>
  );
});
