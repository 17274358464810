// アバター更新状態管理
import { createSlice } from "@reduxjs/toolkit";

// APIリクエスト状態初期設定
const defaultFetchState = {
  dialog: false, // APIリクエスト確認Dialog状態
  updating: false, // APIリクエスト処理中
  updated: false, // APIリクスト完了
};

// 初期値
const initialState = {
  putAvatarState: defaultFetchState,
};

const avatarStateSlice = createSlice({
  name: "avatarState",
  initialState,
  reducers: {
    // アバター編集Dialog開閉関数
    handleEditAvatarDialog: (state) => {
      state.putAvatarState = {
        ...state.putAvatarState,
        dialog: !state.putAvatarState.dialog,
      };
    },
    // アバター更新APIリクエスト状態管理
    handlePutAvatarState: (state, actions) => {
      state.putAvatarState = actions.payload;
    },
  },
});

export const { handleEditAvatarDialog, handlePutAvatarState } =
  avatarStateSlice.actions;

export default avatarStateSlice.reducer;
