// 記事作成編集Dialog用Component_プレビュー本文部分

// 外部ライブラリ
import { memo } from "react";

// プロジェクト内リソース
import { TextEditor } from "../Share/TextEditor";

// ***レンダリング***
export const CreateEditArticleDialogPreviewText = memo(({ previewText }) => {
  // ***props説明***
  // previewText:プレビュー表示本文

  return (
    <TextEditor defaultValue={previewText} editorCore={previewText} readOnly />
  );
});
