// 左側ドロワー表示状態管理(mobile用)
import { createSlice } from "@reduxjs/toolkit";

// 初期値
const initialState = { drawer: false };

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    // ドロワー開閉
    handleDrawer: (state) => {
      state.drawer = !state.drawer;
    },
  },
});

export const { handleDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
