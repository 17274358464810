// お知らせ参照Dialog

// 外部ライブラリ
import { memo, useRef } from "react";
import { Divider } from "@mui/material";

// プロジェクト内リソース
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { TextEditor } from "../Share/TextEditor";
import { ArticleNotificationDialogTemplate } from "../Share/ArticleNotificationDialogTemplate ";
import { LoadingCircular } from "../Share/LoadingCircular";
import { NotificationDialogAdminMenu } from "./NotificationDialogAdminMenu";
import { NotificationDialogTitleArea } from "./NotificationDialogTitleArea";

// ***レンダリング***
export const NotificationDialog = memo((props) => {
  // ***props説明***
  // open: Dialog状態
  // onClickClose: Dialog閉じる関数
  // data: お知らせ単体内容
  const { open, onClickClose, data } = props;

  // ユーザーデータ取得
  const me = useQueryMeApi();

  // お知らせ本文状態
  const editorCore = useRef(null);

  return (
    <ArticleNotificationDialogTemplate
      open={open}
      onClickClose={onClickClose}
      titleContent={
        data &&
        me.data && (
          <>
            {me.data.is_staff && <NotificationDialogAdminMenu data={data} />}
            <NotificationDialogTitleArea data={data} />
            <Divider sx={{ mt: 1 }} />
          </>
        )
      }
      bodyContent={
        data && me.data ? (
          <TextEditor
            readOnly
            editorCore={editorCore}
            defaultValue={data && JSON.parse(data.text)}
          />
        ) : (
          <LoadingCircular />
        )
      }
    />
  );
});
