// MY PAGE/あなたへのお知らせ

// 外部ライブラリ
import { useCallback } from "react";
import { Box, Typography, Divider, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

// プロジェクト内リソース
import { useQueryMessageListApi } from "../hooks/useQueryMessageListApi";
import { useMutateMessageApi } from "../hooks/useMutateMessageApi";
import { ContentCard } from "../Components/Share/ContentCard";
import { MyPageMessageDialog } from "../Components/MyPage/MyPageMessageDialog";
import { MyPageMessageList } from "../Components/MyPage/MyPageMessageList";
import { ScrollBox } from "../Components/Share/ScrollBox";

// ***レンダリング***
export const MyPageMessage = () => {
  // あなたへのお知らせリストデータ取得
  const { data, isLoading } = useQueryMessageListApi();

  // 未読メッセージの既読化APIリクエスト関数
  const { patchIsReadMessageMutation } = useMutateMessageApi();

  // 未読メッセージのフィルタリング
  const unreadMessage =
    data &&
    data.results
      .filter((x) => x.text.type !== "invalid")
      .filter((x) => !x.is_read);

  // 全て既読にする関数
  const onClickPatchAllUnreadMessage = useCallback(() => {
    unreadMessage.map((x) => {
      patchIsReadMessageMutation.mutate(x.id);
    });
  }, [unreadMessage]);

  return (
    <>
      {/* あなたへのお知らせ単体参照Dialog */}
      <MyPageMessageDialog />
      {/* コンテナ部分 */}
      <ContentCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
            mb: 1,
          }}
        >
          <NotificationsIcon />
          <Typography variant="h7" sx={{ ml: 1, flexGrow: 1 }}>
            あなたへのお知らせ
          </Typography>
          <Button
            disabled={
              patchIsReadMessageMutation.isLoading || !unreadMessage.length
            }
            onClick={onClickPatchAllUnreadMessage}
          >
            {patchIsReadMessageMutation.isLoading
              ? "更新中"
              : !unreadMessage.length
              ? "未読のお知らせはありません"
              : "全て既読にする"}
          </Button>
        </Box>
        <Divider />
        <ScrollBox>
          <MyPageMessageList
            messageList={!isLoading && data ? data.results : []}
            loading={isLoading}
          />
        </ScrollBox>
      </ContentCard>
    </>
  );
};
