// 管理者用_お知らせ作成編集Dialog用Component_タイトル他設定エリア_開始終了日用DatePicker

// 外部ライブラリ
import { memo } from "react";
import { TextField, Button, Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// ***レンダリング***
export const CreateEditNotificationDialogDatePicker = memo((props) => {
  // ***props説明***
  // label: ラベル
  // value: 現在選択されている日付
  // onChange: 日付変更関数
  // disabled: 無効設定
  // disablePast: 過去日付の無効化設定
  // isEndDate: 終了日用判定フラグ
  // onClickClear: 日付初期化関数
  // minDate: 選択可能な最小日付
  const { isEndDate, onClickClear } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          {...props}
          inputFormat="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
      </LocalizationProvider>
      {isEndDate && <Button onClick={onClickClear}>clear</Button>}
    </Box>
  );
});
