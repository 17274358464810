// 管理者用_ADMIN/お知らせ管理

// 外部ライブラリ
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import AnnouncementIcon from "@mui/icons-material/Announcement";

// プロジェクト内リソース
import { useQueryAdmNotificationListApi } from "../hooks/useQueryAdmNotificationListApi";
import { onClickCreateNotificationDialogOpen } from "../features/notificationStateSlice";
import { AdminNotificationTable } from "../Components/Admin/AdminNotificationTable";
import { CustomPagination } from "../Components/Share/CustomPagination";
import { ContentCard } from "../Components/Share/ContentCard";
import { CreateNotificationDialog } from "../Components/NotificationDialog/CreateNotificationDialog";
import { PostNotificationCheckDialog } from "../Components/NotificationDialog/PostNotificationCheckDialog";
import { AdminNotificationDialog } from "../Components/Admin/AdminNotificationDialog";
import { EditNotificationDialog } from "../Components/NotificationDialog/EditNotificationDialog";
import { PutNotificationCheckDialog } from "../Components/NotificationDialog/PutNotificationCheckDialog";
import { ScrollBox } from "../Components/Share/ScrollBox";

// ***レンダリング***
export const AdminManageNotification = () => {
  const dispatch = useDispatch();

  // URLからインデックスパラメータを取得
  const { index } = useParams();

  // 管理者用_お知らせリストデータ
  const { data, isLoading } = useQueryAdmNotificationListApi(index);

  return (
    <>
      {/* 管理者用_お知らせ単体表示Dialog */}
      <AdminNotificationDialog />
      {/* 管理者用_新規お知らせ作成・編集Dialog */}
      <CreateNotificationDialog />
      <EditNotificationDialog />
      {/* 管理者用_新規お知らせ投稿・更新確認Dialog */}
      <PostNotificationCheckDialog />
      <PutNotificationCheckDialog />
      {/* コンテナ部分 */}
      <ContentCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
            mb: 2,
          }}
        >
          <AnnouncementIcon />
          <Typography variant="h7" sx={{ ml: 1, flexGrow: 1 }}>
            お知らせ管理
          </Typography>
          <Button
            onClick={() => dispatch(onClickCreateNotificationDialogOpen())}
            sx={{ maxHeight: 0 }}
          >
            新規投稿
          </Button>
        </Box>
        <ScrollBox>
          {/* 管理者用_お知らせ管理テーブル */}
          <AdminNotificationTable />
        </ScrollBox>
        <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
          <CustomPagination
            count={isLoading || !data ? null : data.count}
            loading={isLoading}
          />
        </Box>
      </ContentCard>
    </>
  );
};
