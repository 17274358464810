// ARTICLE/記事閲覧用Component_タイトル他情報エリア_ブックマーク操作

// 外部ライブラリ
import { memo, useCallback } from "react";
import { Box, Tooltip, Zoom } from "@mui/material";
import { useEffect, useRef } from "react";
import Lottie from "lottie-react";

// プロジェクト内リソース
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";
import BookmarkAnimation from "../../Images/BookmarkAnimation.json";

// ***レンダリング***
export const ArticleBookmarkState = memo(({ data }) => {
  // ***props説明***
  // data:記事単体データ

  const lottieRef = useRef(null);

  // ユーザーデータ取得
  const me = useQueryMeApi();

  // ブックマーク状態判定
  const isBookmarked = data.followers.includes(me.data.id);

  // ブックマーク登録/削除APIリクエスト処理関数
  const { putArticleBookmarkMutation, deleteArticleBookmarkMutation } =
    useMutateArticleOptionsApi();

  // ブックマーク登録/削除APIリクエスト処理中状態
  const isLoading =
    putArticleBookmarkMutation.isLoading ||
    deleteArticleBookmarkMutation.isLoading;

  // 初期状態(静止)
  useEffect(() => {
    if (isBookmarked) {
      lottieRef.current.playSegments([[43, 44]], true);
    } else {
      lottieRef.current.playSegments([[4, 5]], true);
    }
  }, []);

  // ブックマークボタン押下時
  const handleBookmark = useCallback(() => {
    // ブックマーク登録済の場合は登録解除APIリクエスト
    if (isBookmarked) {
      lottieRef.current.playSegments([[36, 8]], true);
      deleteArticleBookmarkMutation.mutate({
        id: data.id,
        me_id: me.data.id,
      });
      // それ以外(ブックマーク未登録)の場合は登録APIリクエスト
    } else {
      lottieRef.current.playSegments([[6, 46]], true);
      putArticleBookmarkMutation.mutate({
        id: data.id,
        me_id: me.data.id,
      });
    }
  }, [isBookmarked, lottieRef]);

  return (
    <Box>
      <Tooltip
        title={isBookmarked ? "ブックマークを削除" : "この記事をブックマーク"}
        TransitionComponent={Zoom}
      >
        <span>
          <Lottie
            lottieRef={lottieRef}
            animationData={BookmarkAnimation}
            loop={false}
            autoplay={false}
            style={{
              width: 50,
              height: 50,
              cursor: !isLoading && "pointer",
              pointerEvents: isLoading && "none",
              opacity: isLoading && 0.2,
            }}
            onClick={handleBookmark}
          />
        </span>
      </Tooltip>
    </Box>
  );
});
