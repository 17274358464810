// MY PAGE/自分の記事_自分の記事参照Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

// プロジェクト内リソース
import { ArticleDialog } from "../ArticleDialog/ArticleDialog";
import { handleMyArticleDialog } from "../../features/articleStateSlice";
import { useQueryMyArticleApi } from "../../hooks/useQueryMyArticleApi";

// ***レンダリング***
export const MyPageMyArticleDialog = memo(() => {
  const dispatch = useDispatch();

  // Dialog状態
  const { myArticleDialog } = useSelector((state) => state.articleState);
  // 自分の記事単体データ取得
  const { data } = useQueryMyArticleApi(myArticleDialog.id);

  return (
    <ArticleDialog
      open={myArticleDialog.dialog}
      onClickClose={() => dispatch(handleMyArticleDialog())}
      data={data}
    />
  );
});
