// 管理者用_公開状態切替確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import { useMutateNotificationApi } from "../../hooks/useMutateNotificationApi";
import {
  handleNotificationDialog,
  handlePatchIsActiveNotificationState,
} from "../../features/notificationStateSlice";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const PatchIsActiveNotificationCheckDialog = memo((props) => {
  const dispatch = useDispatch();

  // ***props説明***
  // params: お知らせID/新公開状態
  // onChangeNotificationIsActive: アクティブ状態表示切替関数
  const { params, onChangeNotificationIsActive } = props;

  // お知らせ公開状態切替APIリクエスト状態・お知らせDialog状態
  const { patchIsActiveNotificationState, notificationDialog } = useSelector(
    (state) => state.notificationState
  );
  // お知らせ公開状態切替APIリクエスト関数
  const { patchIsActivedNotificationMutation } = useMutateNotificationApi();

  return (
    <UpdateDbDialog
      open={patchIsActiveNotificationState.dialog}
      onClickCloseDialog={onChangeNotificationIsActive}
      content={
        <Typography color="error">
          このお知らせの公開状態を変更します
        </Typography>
      }
      onClickUpdate={() => patchIsActivedNotificationMutation.mutate(params)}
      updating={patchIsActiveNotificationState.updating}
      updated={patchIsActiveNotificationState.updated}
      onClickCheckUpdated={() => {
        dispatch(
          handlePatchIsActiveNotificationState({
            dialog: false,
            updated: false,
          })
        );
        notificationDialog.dialog && dispatch(handleNotificationDialog());
      }}
    />
  );
});
