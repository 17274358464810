// 新規投稿確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import {
  handleCreateArticlePreviewTab,
  handlePostArticleState,
  onClickClearArticleParams,
  onClickCreateArticleDialogClose,
  handleMyArticleDialog,
} from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const PostArticleCheckDialog = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 新規投稿APIリクエスト状態・自分の記事参照Dialog状態
  const { postArticleState, myArticleDialog } = useSelector(
    (state) => state.articleState
  );

  // 新規投稿APIリクエスト処理関数
  const { postArticleMutation } = useMutateArticleApi();

  return (
    <>
      <UpdateDbDialog
        open={postArticleState.dialog}
        onClickCloseDialog={() =>
          dispatch(handlePostArticleState({ dialog: false }))
        }
        content={<Typography color="error">この内容で投稿します</Typography>}
        onClickUpdate={() => postArticleMutation.mutate()}
        updating={postArticleState.updating}
        updated={postArticleState.updated}
        onClickCheckUpdated={() => {
          dispatch(onClickClearArticleParams());
          dispatch(handlePostArticleState({ dialog: false, updated: false }));
          dispatch(handleCreateArticlePreviewTab(0));
          dispatch(onClickCreateArticleDialogClose());
          myArticleDialog.dialog && dispatch(handleMyArticleDialog());
          navigate("/my_page/my_article/index1");
        }}
      />
    </>
  );
});
