// 管理者用_公開禁止状態変更確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import { handlePatchAdminBannedArticleState } from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const PatchAdminBannedArticleCheckDialog = memo(({ params }) => {
  // ***props説明***
  // params:対象記事情報

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  // 管理者用_公開禁止状態変更APIリクエスト状態・遷移元URL
  const { patchAdminBannedArticleState, previousURL } = useSelector(
    (state) => state.articleState
  );

  // 管理者用_公開禁止状態変更APIリクエスト処理関数
  const { patchAdminBannedArticleMutation } = useMutateArticleApi();

  return (
    <UpdateDbDialog
      open={patchAdminBannedArticleState.dialog}
      onClickCloseDialog={() =>
        dispatch(handlePatchAdminBannedArticleState({ dialog: false }))
      }
      content={
        <Typography color="error">
          {params.is_banned
            ? "この記事を公開禁止にします"
            : "この記事の公開を許可します"}
        </Typography>
      }
      onClickUpdate={() => patchAdminBannedArticleMutation.mutate(params)}
      updating={patchAdminBannedArticleState.updating}
      updated={patchAdminBannedArticleState.updated}
      onClickCheckUpdated={() => {
        dispatch(
          handlePatchAdminBannedArticleState({ dialog: false, updated: false })
        );
        if (pathname == `/article_search/${params.id}`) {
          previousURL
            ? navigate(previousURL)
            : navigate("/admin/article/index1");
        }
      }}
    />
  );
});
