// 記事・コメント投稿者表示状態管理
import { createSlice } from "@reduxjs/toolkit";

// 初期値
const initialState = {
  articleOwnerDialog: {
    id: "",
    display_name: "",
    email: "",
    dialog: false, // Dialog状態
  },
};

const articleOwnerSlice = createSlice({
  name: "articleOwner",
  initialState,
  reducers: {
    // 記事・コメント投稿者表示Dialog開閉関数
    handleArticleOwnerDialog: (state, actions) => {
      // Dialogを開く時のみ投稿者のユーザーIDを取得
      if (!state.articleOwnerDialog.dialog) {
        state.articleOwnerDialog = { dialog: true, ...actions.payload };
      } else {
        state.articleOwnerDialog = initialState.articleOwnerDialog;
      }
    },
  },
});

export const { handleArticleOwnerDialog } = articleOwnerSlice.actions;

export default articleOwnerSlice.reducer;
