// HOME/Welcome・全てのお知らせ用Component_お知らせ参照Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

// プロジェクト内リソース
import { handleNotificationDialog } from "../../features/notificationStateSlice";
import { useQueryNotificationApi } from "../../hooks/useQueryNotificationApi";
import { NotificationDialog } from "../NotificationDialog/NotificationDialog";

// ***レンダリング***
export const HomeNotificationDialog = memo(() => {
  const dispatch = useDispatch();

  // Dialog状態
  const { notificationDialog } = useSelector(
    (state) => state.notificationState
  );

  // お知らせ単体データ取得
  const { data } = useQueryNotificationApi(notificationDialog.id);

  return (
    <NotificationDialog
      open={notificationDialog.dialog}
      onClickClose={() => dispatch(handleNotificationDialog())}
      data={data}
    />
  );
});
