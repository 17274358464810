// 共通Component_LoadingBar(ロード完了時に画面が動かないよう、同じ高さの空白で穴埋め)

// 外部ライブラリ
import { memo } from "react";
import { Box, LinearProgress } from "@mui/material";

// ***レンダリング***
export const LoadingBar = memo(({ loading }) => {
  // ***props説明***
  // loading: データローディング状態

  return (
    <>
      {loading ? (
        <LinearProgress sx={{ height: 2 }} />
      ) : (
        <Box sx={{ height: 2 }} />
      )}
    </>
  );
});
