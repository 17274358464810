// 記事のいいね・ブックマーク・コメント更新用のAPIリクエスト関数(useMutation)

// 外部ライブラリ
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";
import { handleArticleCommentUpdating } from "../features/articleStateSlice";

export const useMutateArticleOptionsApi = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    articleCreateComment,
    articleCreateReplyCommentParams,
    articleEditDeleteCommentParams,
  } = useSelector((state) => state.articleState);

  // ***いいね***
  // 記事のいいね登録/解除(POST)
  const postArticleLikeMutation = useMutation(
    (id) => client.post(`/article/${id}/like/`),
    {
      // リクエスト成功時処理
      onSuccess: () => {
        queryClient.invalidateQueries(["article"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  //  ***ブックマーク***
  // 記事のブックマーク登録(PUT)
  const putArticleBookmarkMutation = useMutation(
    (params) => client.put(`article/${params.id}/bookmark/${params.me_id}/`),
    {
      // リクエスト成功時処理
      onSuccess: () => {
        queryClient.invalidateQueries(["article"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // 記事のブックマーク削除(DELETE)
  const deleteArticleBookmarkMutation = useMutation(
    (params) => client.delete(`article/${params.id}/bookmark/${params.me_id}/`),
    {
      // リクエスト成功時処理
      onSuccess: () => {
        queryClient.invalidateQueries(["article"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  //  ***コメント***
  // 記事へのコメント投稿(POST)
  const postArticleCommentMutation = useMutation(
    (id) =>
      client.post(`article/${id}/comment/`, {
        ancestor: null,
        text: articleCreateComment,
      }),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // コメントの返信投稿(POST)
  const postArticleReplyCommentMutation = useMutation(
    (id) =>
      client.post(`article/${id}/comment/`, articleCreateReplyCommentParams),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // コメントの編集(PATCH)
  const patchArticleCommentMutation = useMutation(
    (id) =>
      client.patch(
        `article/${id}/comment/${articleEditDeleteCommentParams.id}/`,
        { text: articleEditDeleteCommentParams.text }
      ),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // コメントの削除(DELETE)
  const deleteArticleCommentMutation = useMutation(
    (id) =>
      client.delete(
        `article/${id}/comment/${articleEditDeleteCommentParams.id}/`
      ),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  return {
    postArticleLikeMutation,
    putArticleBookmarkMutation,
    deleteArticleBookmarkMutation,
    postArticleCommentMutation,
    postArticleReplyCommentMutation,
    patchArticleCommentMutation,
    deleteArticleCommentMutation,
  };
};
