// 作成者情報参照Dialog

// 外部ライブラリ
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
  Typography,
  Badge,
  Button,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

// プロジェクト内リソース
import { handleArticleOwnerDialog } from "../../features/articleOwnerSlice";
import {
  handleAdminArticleDialog,
  handleMyArticleDialog,
} from "../../features/articleStateSlice";
import { onChangeArticleSearchParams } from "../../features/articleSearchParamsSlice";
import { CustomAvatar } from "../Share/CustomAvatar";

// ***レンダリング***
export const ArticleOwnerDialog = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Dialog状態
  const { articleOwnerDialog } = useSelector((state) => state.articleOwner);

  // 自分の記事参照画面用Dialog状態・管理者用_記事管理画面用記事参照Dialog状態
  const { myArticleDialog, adminArticleDialog } = useSelector(
    (state) => state.articleState
  );

  // 選択した作成者の記事を検索
  const onClickSearchThisOwnersArticle = useCallback(
    (id) => {
      dispatch(
        onChangeArticleSearchParams({
          author: id,
          tags: [],
          keyword: "",
          bookmarked: false,
          myArticle: false,
        })
      );
      dispatch(handleArticleOwnerDialog());
      myArticleDialog.dialog && dispatch(handleMyArticleDialog());
      adminArticleDialog.dialog && dispatch(handleAdminArticleDialog());
      navigate(`/article_search/results/index1?author=${id}`);
    },
    [myArticleDialog, adminArticleDialog]
  );

  return (
    <Dialog
      open={articleOwnerDialog.dialog}
      onClose={() => dispatch(handleArticleOwnerDialog())}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ pb: 0 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => dispatch(handleArticleOwnerDialog())}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Badge overlap="circular">
            <CustomAvatar
              data={articleOwnerDialog}
              sx={{ height: 86, width: 86 }}
            />
          </Badge>
          <Box sx={{ ml: 2 }}>
            <Typography variant="h6">
              {articleOwnerDialog.display_name}
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 24,
                }}
              >
                <MailOutlineIcon />
                <Typography sx={{ ml: 1 }}>
                  {articleOwnerDialog.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            variant="contained"
            size="medium"
            startIcon={<SearchIcon />}
            onClick={() => onClickSearchThisOwnersArticle(ownerDialog.id)}
          >
            この人が書いた記事
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
});
