// 公開状態切替確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import { handlePatchIsPublishedArticleState } from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const PatchIsPubulishedArticleCheckDialog = memo((props) => {
  // ***props説明***
  // params:対象記事情報
  // onChangeArticleIsPublished:公開状態変更関数
  const { params, onChangeArticleIsPublished } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  // 公開状態変更APIリクエスト状態・遷移元URL
  const { patchIsPublishedArticleState, previousURL } = useSelector(
    (state) => state.articleState
  );
  // 公開状態変更APIリクエスト処理関数
  const { patchIsPublishedArticleMutation } = useMutateArticleApi();

  return (
    <UpdateDbDialog
      open={patchIsPublishedArticleState.dialog}
      onClickCloseDialog={onChangeArticleIsPublished}
      content={
        <Typography color="error">この記事の公開状態を変更します</Typography>
      }
      onClickUpdate={() => patchIsPublishedArticleMutation.mutate(params)}
      updating={patchIsPublishedArticleState.updating}
      updated={patchIsPublishedArticleState.updated}
      onClickCheckUpdated={() => {
        dispatch(
          handlePatchIsPublishedArticleState({ dialog: false, updated: false })
        );
        if (pathname == `/article_search/${params.id}`) {
          previousURL
            ? navigate(previousURL)
            : navigate("/my_page/my_article/index1");
        }
      }}
    />
  );
});
