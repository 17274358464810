// 記事コメントリスト取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getArticleComent = async (id) => {
  const { data } = await client.get(`article/${id}/comment/`);
  return data;
};

export const useQueryArticleCommentApi = (id) => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // refetchOnMount: マウント都度データを再取得する設定
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["article_comment"],
    queryFn: () => getArticleComent(id),
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
  });
};
