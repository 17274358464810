// 共通Component_コンテンツカード

// 外部ライブラリ
import { Card } from "@mui/material";

// ***レンダリング***
export const ContentCard = ({ children, sx }) => {
  // ***props説明***
  // children: カード内に表示する子コンポーネント
  // sx: スタイル設定

  return (
    <Card
      sx={{
        p: 2,
        bgcolor: "rgba(255,255,255,0.8)",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
