// 記事作成編集Dialog用Component_プレビュータブ選択状態に応じた上部タイトル他情報エリア表示内容切替

// 外部ライブラリ
import { memo, useState } from "react";

// プロジェクト内リソース
import { useQueryTagApi } from "../../hooks/useQueryTag";
import { CreateEditArticleDialogPreviewTitleArea } from "./CreateEditArticleDialogPreviewTitleArea";
import { CreateEditArticleDialogTitleArea } from "./CreateEditArticleDialogTitleArea";

// ***レンダリング***
export const CreateEditArticleDialogSwitchInputPreview = memo((props) => {
  // ***props説明***
  // isPost: 記事作成画面用判定フラグ
  // defaultTags: 選択タグ初期値(編集用)
  // previewTab: プレビュータブ選択状態
  const { isPost, defaultTags, previewTab } = props;

  // タグデータ取得
  const { data, isLoading } = useQueryTagApi();
  const [tagList, setTagList] = useState(data.map((x) => x.name));

  // データ取得中は表示しない
  if (isLoading) return;

  return (
    <>
      {previewTab ? (
        <CreateEditArticleDialogPreviewTitleArea />
      ) : (
        <CreateEditArticleDialogTitleArea
          tagList={tagList}
          setTagList={setTagList}
          defaultTags={defaultTags}
          isPost={isPost}
        />
      )}
    </>
  );
});
