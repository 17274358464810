// ARTICLE/タグから探す

// 外部ライブラリ
import {
  Box,
  Typography,
  Divider,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

// プロジェクト内リソース
import { shapeTags } from "../utils/utils";
import { useQueryTagApi } from "../hooks/useQueryTag";
import { onChangeArticleSearchParams } from "../features/articleSearchParamsSlice";
import { ContentCard } from "../Components/Share/ContentCard";
import { LoadingBar } from "../Components/Share/LoadingBar";
import { ScrollBox } from "../Components/Share/ScrollBox";

// ***レンダリング***
export const ArticleSearchTags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // タグデータ取得
  const { data, isLoading } = useQueryTagApi();

  // 必要データ読み込み中は表示しない
  if (isLoading) return;

  // コンテナ部分
  return (
    <>
      <ContentCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
            mb: 1.2,
          }}
        >
          <LocalOfferIcon />
          <Typography variant="h7" sx={{ ml: 1 }}>
            タグから探す
          </Typography>
          <Chip
            size="small"
            label={`Tag Count:${shapeTags(data).length}`}
            sx={{ px: 1, ml: 2 }}
          />
        </Box>
        <Divider sx={{ mt: 0.6 }} />
        <ScrollBox>
          <LoadingBar loading={isLoading} />
          <List disablePadding>
            {data.length ? (
              data.map((item, index) => {
                return (
                  <Box key={index}>
                    <ListItem>
                      {/* 選択タグに絞り検索を開始する */}
                      <ListItemButton
                        onClick={() => {
                          dispatch(
                            onChangeArticleSearchParams({
                              author: "",
                              tags: [item],
                              keyword: "",
                              bookmarked: false,
                            })
                          );
                          navigate(
                            `/article_search/results/index1?tags=${item.slug}`
                          );
                        }}
                        sx={{ py: 1, color: "primary.main" }}
                      >
                        <ListItemText primary={`#${item.name}`} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <Box
                textAlign="center"
                sx={{ display: isLoading && "none", mt: 2 }}
              >
                <Typography>タグはありません</Typography>
              </Box>
            )}
          </List>
        </ScrollBox>
      </ContentCard>
    </>
  );
};
