// ARTICLE/記事閲覧用Component_コメント新規作成

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Box, Button } from "@mui/material";

// プロジェクト内リソース
import { onChangeArticleComment } from "../../features/articleStateSlice";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";

// ***レンダリング***
export const ArticleCommentsPostArea = memo(({ id }) => {
  // ***props説明***
  // id:記事ID

  const dispatch = useDispatch();

  // 作成中コメント状態・コメント更新APIリクスト状態
  const { articleCreateComment, articleCommentUpdating } = useSelector(
    (state) => state.articleState
  );

  // コメント新規投稿APIリクエスト処理関数
  const { postArticleCommentMutation } = useMutateArticleOptionsApi();

  return (
    <Box sx={{ display: "flex", pt: 2 }}>
      <Box sx={{ width: "90%", mx: 2 }}>
        <TextField
          fullWidth
          minRows={2}
          multiline
          label="この記事にコメントする"
          value={articleCreateComment}
          onChange={(event) =>
            dispatch(onChangeArticleComment(event.target.value))
          }
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => {
            postArticleCommentMutation.mutate(id);
            dispatch(onChangeArticleComment(""));
          }}
          disabled={!articleCreateComment || articleCommentUpdating}
        >
          送信
        </Button>
      </Box>
    </Box>
  );
});
