//　ナビゲーションサイドメニュー用Component_サブメニューエリア

// 外部ライブラリ
import { memo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Typography,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

// プロジェクト内リソース
import { sideMenuSubItemsList } from "./SideMenuItemsList";
import { firstPathname } from "../../../utils/utils";

// ***レンダリング***
export const SideMenuSubMenuArea = memo(({ loading }) => {
  // ***props説明***
  // loading: データローディング状態

  const navigate = useNavigate();

  // URLパラメータ取得
  const pathname = useLocation().pathname;
  const { id, index } = useParams();

  // 現在のURLに基づき表示するサブメニュー項目を取得
  const subMenuItems = sideMenuSubItemsList(firstPathname(pathname));

  // ページ遷移前のURLを取得
  const { previousURL } = useSelector((state) => state.articleState);

  return (
    <>
      {subMenuItems && (
        <Box
          sx={{
            height: "calc(100vh - 64px)",
            minWidth: 288,
            overflow: "auto",
            mt: { xs: 0, sm: 0, md: 8 },
            py: 3,
            borderRight: "0.1px solid rgba(100,100,100,0.4)",
          }}
        >
          <Box
            sx={{ display: { md: "none", lg: "none" }, textAlign: "center" }}
          >
            <Typography color="primary" variant="h5">
              Knowledge campus
            </Typography>
            <Divider sx={{ mt: 2, mb: 2.8 }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1.6,
              mb: 3,
              color: "base.contrastText",
            }}
          >
            {subMenuItems.icon}
            <Typography variant="h6" sx={{ ml: 0.6 }}>
              {subMenuItems.label}
            </Typography>
          </Box>
          <Divider />
          <List disablePadding>
            {pathname.includes("/article_search") && (
              <>
                <Box>
                  <ListItemButton
                    sx={{
                      py: 2.4,
                      borderRight:
                        pathname == `/article_search/${id}` &&
                        "4px solid #50ace4",
                    }}
                    disabled
                    selected={pathname == `/article_search/${id}`}
                  >
                    <ListItemIcon>
                      <DocumentScannerIcon />
                    </ListItemIcon>
                    <ListItemText primary="記事閲覧" />
                  </ListItemButton>
                </Box>
                <Box>
                  <ListItemButton
                    sx={{
                      py: 2.4,
                      color:
                        previousURL &&
                        pathname == `/article_search/${id}` &&
                        "primary.main",
                      borderRight:
                        pathname == `/article_search/results/index${index}` &&
                        "4px solid #50ace4",
                    }}
                    disabled={
                      !previousURL || pathname != `/article_search/${id}`
                    }
                    selected={
                      pathname == `/article_search/results/index${index}`
                    }
                    onClick={() => navigate(previousURL)}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          previousURL &&
                          pathname == `/article_search/${id}` &&
                          "primary.main",
                      }}
                    >
                      <SearchIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        previousURL && pathname == `/article_search/${id}`
                          ? "検索結果に戻る"
                          : "検索結果"
                      }
                    />
                  </ListItemButton>
                  <Divider />
                </Box>
              </>
            )}
            {subMenuItems.menuItems.map((item, index) => {
              return (
                <Box key={index}>
                  <ListItemButton
                    sx={{
                      color: "primary.main",
                      py: 2.4,
                      borderRight:
                        pathname == item.active && "4px solid #50ace4",
                    }}
                    disabled={loading}
                    selected={pathname == item.active}
                    onClick={() => navigate(item.path)}
                  >
                    <ListItemIcon sx={{ color: "primary.main" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                  <Divider />
                </Box>
              );
            })}
          </List>
        </Box>
      )}
    </>
  );
});
