// ARTICLE/記事閲覧用Component_タイトル他情報エリア

// 外部ライブラリ
import { memo, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import BlockIcon from "@mui/icons-material/Block";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

// プロジェクト内リソース
import { useQueryArticleCommentApi } from "../../hooks/useQueryArticleCommentApi";
import { CustomAvatar } from "../Share/CustomAvatar";
import { ArticleTagList } from "./ArticleTagList";
import { ArticleLikeState } from "./ArticleLikeState";
import { ArticleBookmarkState } from "./ArticleBookmarkState";

// ***レンダリング***
export const ArticleTitleArea = memo(({ data }) => {
  // ***props説明***
  // data:記事データ

  // コメントデータ取得
  const comment = useQueryArticleCommentApi(data.id);
  // 記事リンクコピーボタン用ステート
  const [copied, setCopied] = useState(false);

  //記事のリンクをクリップへコピー
  const onClickCopyLinkToClipboard = async () => {
    await global.navigator.clipboard.writeText(
      `${process.env.REACT_APP_REDIRECT_URL}article_search/${data.id}`
    );
    setCopied(true);
  };

  return (
    <>
      {data.is_banned && (
        <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
          <BlockIcon color="error" />
          <Typography variant="h7" color="error">
            この記事は管理者により公開が禁止されています
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={2}>
          <CustomAvatar data={data.owner} sx={{ width: 58, height: 58 }} />
        </Box>
        <Box sx={{ textOverflow: "ellipsis" }}>
          <Typography>{`@${data.owner.display_name}`}</Typography>
          <Typography variant="subtitle2">
            {data.initial_published_at &&
              `投稿日:${data.initial_published_at} | 更新日:${data.last_published_at}`}
          </Typography>
        </Box>
      </Box>
      <Typography variant="h5" color="primary" sx={{ textAlign: "center" }}>
        {data.title}
      </Typography>
      <ArticleTagList tagItems={data.tags} />
      <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
        <ArticleLikeState data={data} />
        <CommentIcon sx={{ color: "base.contrastText", ml: 2.6 }} />
        <Typography variant="subtitle2" sx={{ ml: 1, minWidth: 16 }}>
          {comment.data ? comment.data.length : "-"}
        </Typography>
        <StarOutlineIcon sx={{ color: "base.contrastText", ml: 2 }} />
        <Typography variant="subtitle2" sx={{ ml: 1, flexGrow: 1 }}>
          {data.followers.length}
        </Typography>
        <Box>
          <Button
            startIcon={<ContentCopyIcon />}
            onClick={onClickCopyLinkToClipboard}
            onMouseLeave={() => setCopied(false)}
          >
            <Typography sx={{ width: 128, fontSize: 12 }}>
              {!copied ? "記事のリンクをコピー" : "コピーしました！"}
            </Typography>
          </Button>
        </Box>
        <ArticleBookmarkState data={data} />
      </Box>
    </>
  );
});
