// あなたへのお知らせを既読にするAPIリクエスト関数(useMutation)

// 外部ライブラリ
import { useMutation, useQueryClient } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

export const useMutateMessageApi = () => {
  const queryClient = useQueryClient();

  //  既読に変更(PATCH)
  const patchIsReadMessageMutation = useMutation(
    // リクエストボディ:ユーザーID・既読フラグ
    (id) => client.patch(`/message/${id}/`, { is_read: true }),
    {
      // リクエスト成功時処理
      onSuccess: () => {
        queryClient.invalidateQueries(["message_list"]);
      },
    }
  );

  return { patchIsReadMessageMutation };
};
