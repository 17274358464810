// 管理者用_お知らせ更新確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import { useMutateNotificationApi } from "../../hooks/useMutateNotificationApi";
import {
  handleAdminNotificationDialog,
  handleEditNotificationDialog,
  handleNotificationDialog,
  handlePutNotificationState,
  onClickClearNotificationParams,
} from "../../features/notificationStateSlice";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const PutNotificationCheckDialog = memo(() => {
  const dispatch = useDispatch();

  // お知らせ参照Dialog開閉状態・管理者画面用お知らせDialog開閉状態・更新APIリクエスト状態
  const { notificationDialog, adminNotificationDialog, putNotificationState } =
    useSelector((state) => state.notificationState);

  // 更新APIリクエスト関数
  const { putNotificationMutation } = useMutateNotificationApi();

  return (
    <UpdateDbDialog
      open={putNotificationState.dialog}
      onClickCloseDialog={() =>
        dispatch(handlePutNotificationState({ dialog: false }))
      }
      content={<Typography color="error">この内容で更新します</Typography>}
      onClickUpdate={() => putNotificationMutation.mutate()}
      updating={putNotificationState.updating}
      updated={putNotificationState.updated}
      onClickCheckUpdated={() => {
        dispatch(onClickClearNotificationParams());
        dispatch(handlePutNotificationState({ dialog: false, updated: false }));
        dispatch(handleEditNotificationDialog());
        notificationDialog.dialog && dispatch(handleNotificationDialog());
        adminNotificationDialog.dialog &&
          dispatch(handleAdminNotificationDialog());
      }}
    />
  );
});
