// 記事作成Dialog

// 外部ライブラリ
import { useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";

// プロジェクト内リソース
import {
  handleCreateArticlePreviewTab,
  handlePostArticleState,
  onChangeArticleParams,
  onClickCreateArticleDialogClose,
} from "../../features/articleStateSlice";
import { TextEditor } from "../Share/TextEditor";
import { ArticleNotificationDialogTemplate } from "../Share/ArticleNotificationDialogTemplate ";
import { CreateEditArticleNotificationDialogMainMenu } from "../Share/CreateEditArticleNotificationDialogMainMenu";
import { CreateEditArticleDialogSwitchInputPreview } from "./CreateEditArticleDialogSwitchInputPreview";
import { CreateEditArticleDialogPreviewText } from "./CreateEditArticleDialogPreviewText";

// ***レンダリング***
export const CreateArticleDialog = () => {
  const dispatch = useDispatch();

  // 記事本文状態
  const editorCore = useRef(null);

  // Dialog状態・作成中記事状態・作成中記事プレビュータブ選択状態
  const { createArticleDialog, articleParams, createArticlePreviewTab } =
    useSelector((state) => state.articleState);

  // タイトル文字数制限
  const capsLimit = articleParams.title.length > 180;

  // 投稿・更新ボタン無効条件
  const disableButton =
    !articleParams.title || !articleParams.tags.length || capsLimit;

  // プレビューEditor本文
  const [previewText, setPreviewText] = useState();

  // プレビュー・編集選択Tab
  const handlePreviewTab = useCallback(
    async (event, value) => {
      if (!createArticlePreviewTab) {
        const saveCore = await editorCore.current.save();
        setPreviewText(saveCore);
        dispatch(onChangeArticleParams({ text: JSON.stringify(saveCore) }));
      }
      dispatch(handleCreateArticlePreviewTab(value));
    },
    [createArticlePreviewTab, editorCore]
  );

  // 投稿押下時、editorの編集内容をグローバルステートへ反映
  const onClickPostArticleCheckDialogOpen = useCallback(async () => {
    if (!createArticlePreviewTab) {
      const saveCore = await editorCore.current.save();
      dispatch(onChangeArticleParams({ text: JSON.stringify(saveCore) }));
    }
    dispatch(handlePostArticleState({ dialog: true }));
  }, [createArticlePreviewTab, editorCore]);

  return (
    <>
      <ArticleNotificationDialogTemplate
        open={createArticleDialog}
        onClickClose={() => dispatch(onClickCreateArticleDialogClose())}
        titleContent={
          <>
            <CreateEditArticleNotificationDialogMainMenu
              label="記事作成"
              handleFetchDialog={onClickPostArticleCheckDialogOpen}
              tab={createArticlePreviewTab}
              handleTab={handlePreviewTab}
              disableButton={disableButton}
              isPost
            />
            <Divider sx={{ mb: 2 }} />
            <CreateEditArticleDialogSwitchInputPreview
              previewTab={createArticlePreviewTab}
              isPost
            />
            <Divider sx={{ mt: 2 }} />
          </>
        }
        bodyContent={
          createArticlePreviewTab ? (
            <CreateEditArticleDialogPreviewText previewText={previewText} />
          ) : (
            <TextEditor
              defaultValue={
                articleParams.text && JSON.parse(articleParams.text)
              }
              editorCore={editorCore}
            />
          )
        }
      />
    </>
  );
};
