//　ヘッダー用Component_検索エリア_検索キーワード入力フィールド

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Chip,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterListIcon from "@mui/icons-material/FilterList";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

// プロジェクト内リソース
import { onChangeArticleSearchParams } from "../../../features/articleSearchParamsSlice";

// ***レンダリング***
export const HeaderSearchAreaInputField = memo((props) => {
  const dispatch = useDispatch();

  // ***props説明***
  // articleSearchMenuPopper: 検索設定Popper状態
  // articleSearchMenuAnchorRef: 検索設定Popperのアンカー要素の参照
  // onFocusArticleSearchMenuPopperOpen: 検索設定Popperを開くためのイベントハンドラ
  // loading: データローディング状態
  // handleKeyDown: キーボード操作イベントハンドラ
  const {
    articleSearchMenuPopper,
    articleSearchMenuAnchorRef,
    onFocusArticleSearchMenuPopperOpen,
    loading,
    handleKeyDown,
  } = props;

  // 検索条件パラメーター
  const { articleSearchParams } = useSelector(
    (state) => state.articleSearchParams
  );

  // 検索条件_フィルター項目設定
  const filterItems = [
    { name: "Bookmark", value: articleSearchParams.bookmarked },
    {
      name: "My Article",
      value: articleSearchParams.myArticle,
    },
    {
      name: "Author",
      value: !articleSearchParams.myArticle && articleSearchParams.author,
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        fullWidth
        size={articleSearchMenuPopper ? "medium" : "small"}
        autoComplete="off"
        placeholder="ナレッジを探しましょう！"
        value={articleSearchParams.keyword}
        onChange={(event) =>
          dispatch(onChangeArticleSearchParams({ keyword: event.target.value }))
        }
        onKeyDown={handleKeyDown}
        onClick={onFocusArticleSearchMenuPopperOpen}
        ref={articleSearchMenuAnchorRef}
        disabled={loading}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  {articleSearchMenuPopper && (
                    <IconButton
                      onClick={() =>
                        dispatch(onChangeArticleSearchParams({ keyword: "" }))
                      }
                      disabled={loading}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                  <LocalOfferIcon fontSize="small" />
                  <Box
                    sx={{
                      minWidth: 228,
                      maxWidth: 326,
                      overflow: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {articleSearchParams.tags.map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          size="small"
                          label={item.name}
                          sx={{
                            fontSize: 12,
                            ml: 1,
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", xl: "flex" },
                    alignItems: "center",
                    minWidth: 118,
                  }}
                >
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                  <FilterListIcon fontSize="small" />
                  {filterItems.map((item, index) => {
                    return (
                      <Chip
                        key={index}
                        size="small"
                        label={item.name}
                        sx={{
                          fontSize: 12,
                          ml: 1,
                          display: item.value ? "block" : "none",
                        }}
                      />
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", xl: "flex" },
                    alignItems: "center",
                    minWidth: 98,
                  }}
                >
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                  <SettingsIcon fontSize="small" />
                  <Chip
                    size="small"
                    label="Title Only"
                    sx={{
                      fontSize: 12,
                      ml: 1,
                      display:
                        articleSearchParams.scope == "fulltext"
                          ? "none"
                          : "block",
                    }}
                  />
                  <Chip
                    size="small"
                    label={articleSearchParams.or}
                    sx={{ fontSize: 12, ml: 1 }}
                  />
                </Box>
              </Box>
            </InputAdornment>
          ),
        }}
        sx={{
          pointerEvents: loading && "none",
          width: { xs: "58vw", sm: "58vw", md: "40vw", lg: "56vw" },
          bgcolor: !articleSearchMenuPopper && "rgba(155,155,155,0.22)",
          "&.MuiTextField-root": {
            borderRadius: "33px",
            "& fieldset": {
              border: "none",
              borderBottom:
                articleSearchMenuPopper && "0.5px solid rgba(0,0,0,0.1)",
              borderRadius: { xs: "33px", sm: "20px 20px 0 0" },
              boxShadow: articleSearchMenuPopper
                ? "0px 8px 18px -2px rgba(85, 85, 85, 0.75)"
                : "(0 0 5px 5px #f4c55f, 0 0 5px 5px #f4c55f inset)",
            },
          },
        }}
      />
    </Box>
  );
});
