//　ARTICLE:各検索条件共通_検索結果画面用Component_記事リスト

// 外部ライブラリ
import { memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Chip,
  IconButton,
  Tooltip,
  Zoom,
  Link,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import CommentIcon from "@mui/icons-material/Comment";
import TabIcon from "@mui/icons-material/Tab";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

// プロジェクト内リソース
import {
  handleGlimpseArticleDialog,
  onClickSavePreviousURL,
} from "../../features/articleStateSlice";
import { handleArticleOwnerDialog } from "../../features/articleOwnerSlice";
import { ListItemBox } from "../Share/ListItemBox";
import { LoadingBar } from "../Share/LoadingBar";
import { CustomAvatar } from "../Share/CustomAvatar";

// ***レンダリング***
export const ArticleSearchList = memo((props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // URLより指定検索条件を取得
  const pathname = useLocation().pathname;
  const searchParams = useLocation().search;

  // ***props説明***
  // articleList: 記事リスト
  // loading: データローディング状態
  // isHome: ホーム画面用判定フラグ
  const { articleList, loading, isHome } = props;

  return (
    <>
      <LoadingBar loading={loading} />
      <List disablePadding>
        {articleList.length ? (
          articleList.map((item, index) => {
            return (
              <Box key={index}>
                <ListItem>
                  <ListItemAvatar
                    sx={{
                      cursor: !isHome && "pointer",
                      pointerEvents: isHome && "none",
                    }}
                    onClick={() =>
                      dispatch(handleArticleOwnerDialog(item.owner))
                    }
                  >
                    <CustomAvatar
                      data={item.owner}
                      sx={
                        isHome
                          ? { width: 38, height: 38 }
                          : { width: 62, height: 62 }
                      }
                    />
                  </ListItemAvatar>
                  <ListItemButton
                    onClick={async () => {
                      await dispatch(
                        onClickSavePreviousURL(pathname + searchParams)
                      );
                      await navigate(`/article_search/${item.id}`);
                    }}
                    sx={{ py: 0 }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <ListItemBox>
                            <Typography variant={isHome && "body2"}>
                              {`@${item.owner.display_name}`}
                            </Typography>
                          </ListItemBox>
                          <ListItemBox>
                            {isHome ? (
                              <Typography variant="subtitle2">
                                {`更新日:${item.last_published_at}`}
                              </Typography>
                            ) : (
                              <Typography variant="subtitle2">
                                {`投稿日:${item.initial_published_at} | 更新日:${item.last_published_at}`}
                              </Typography>
                            )}
                          </ListItemBox>
                          <ListItemBox>
                            <Typography
                              color="primary"
                              variant={isHome ? "h7" : "h6"}
                            >
                              {item.title}
                            </Typography>
                          </ListItemBox>
                          <Box
                            sx={{
                              display: isHome ? "none" : "flex",
                              alignItems: "center",
                              mt: 0.4,
                              overflow: "hidden",
                            }}
                          >
                            <LocalOfferIcon
                              sx={{
                                color: "base.contrastText",
                                fontSize: 14,
                                mr: 1,
                              }}
                            />
                            {item.tags.map((item) => {
                              return (
                                <Chip
                                  key={item}
                                  label={item}
                                  size="small"
                                  sx={{
                                    fontSize: 12,
                                    mx: 0.2,
                                    cursor: "pointer",
                                  }}
                                />
                              );
                            })}
                          </Box>
                          <Box
                            sx={{
                              mt: 0.3,
                              display: isHome ? "none" : "flex",
                              alignItems: "center",
                            }}
                          >
                            <ThumbUpAltIcon
                              sx={{
                                color: "base.contrastText",
                                fontSize: 14,
                                mr: 1,
                              }}
                            />
                            <Typography variant="body2">
                              {item.like.length}
                            </Typography>
                            <CommentIcon
                              sx={{
                                color: "base.contrastText",
                                fontSize: 14,
                                ml: 2,
                                mr: 1,
                              }}
                            />
                            <Typography variant="body2">
                              {item.comments.length}
                            </Typography>
                            <StarOutlineIcon
                              sx={{
                                color: "base.contrastText",
                                fontSize: 14,
                                ml: 2,
                                mr: 1,
                              }}
                            />
                            <Typography variant="body2">
                              {item.followers.length}
                            </Typography>
                          </Box>
                        </>
                      }
                    />
                  </ListItemButton>
                  {!isHome && (
                    <>
                      <Tooltip title="チラ見" TransitionComponent={Zoom}>
                        <IconButton
                          disabled={loading}
                          onClick={() =>
                            dispatch(handleGlimpseArticleDialog(item.id))
                          }
                        >
                          <ManageSearchIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="別タブで開く" TransitionComponent={Zoom}>
                        <IconButton
                          sx={{ ml: 1 }}
                          LinkComponent={Link}
                          href={`${process.env.REACT_APP_REDIRECT_URL}article_search/for_another_tag/${item.id}`}
                          target="_blank"
                          disabled={loading}
                        >
                          <TabIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </ListItem>
                <Divider sx={{ mx: 2 }} />
              </Box>
            );
          })
        ) : (
          <Box textAlign="center" sx={{ display: loading && "none", mt: 2 }}>
            <Typography>指定した条件に一致する記事はありません</Typography>
          </Box>
        )}
      </List>
    </>
  );
});
