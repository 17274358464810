// 記事投稿や更新用のAPIリクエスト関数(useMutation)

// 外部ライブラリ
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";

// プロジェクト内リソース
import {
  handleDeleteArticleState,
  handlePatchIsPublishedArticleState,
  handlePatchAdminBannedArticleState,
  handlePostArticleState,
  handlePutArticleState,
} from "../features/articleStateSlice";
import { client } from "../utils/authClient";

export const useMutateArticleApi = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { articleParams } = useSelector((state) => state.articleState);

  // 記事の新規投稿(POST)
  const postArticleMutation = useMutation(
    () =>
      // リクエストボディ:記事のタイトル・本文・タグ・公開状態
      client.post("/my_article/", {
        title: articleParams.title,
        text: articleParams.text,
        tags: articleParams.tags,
        is_published: articleParams.is_published,
      }),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => {
        dispatch(handlePostArticleState({ updating: true }));
      },
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handlePostArticleState({ updating: false, updated: true }));
        queryClient.invalidateQueries(["tag"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // 記事の更新(PUT)
  const putArticleMutation = useMutation(
    () =>
      // リクエストボディ:記事のタイトル・本文・タグ・公開状態
      client.put(`/my_article/${articleParams.id}/`, {
        title: articleParams.title,
        text: articleParams.text,
        tags: articleParams.tags,
        is_published: articleParams.is_published,
      }),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => {
        dispatch(handlePutArticleState({ updating: true }));
      },
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handlePutArticleState({ updating: false, updated: true }));
        queryClient.invalidateQueries(["tag"]);
        articleParams.is_published &&
          queryClient.invalidateQueries(["article"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // 記事の公開状態変更(PATCH)
  const patchIsPublishedArticleMutation = useMutation(
    (params) =>
      // リクエストボディ:公開状態
      client.patch(`/my_article/${params.id}/`, {
        is_published: params.is_published,
      }),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => {
        dispatch(handlePatchIsPublishedArticleState({ updating: true }));
      },
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(
          handlePatchIsPublishedArticleState({ updating: false, updated: true })
        );
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // 管理者による記事の公開禁止・許可変更（PATCH）
  const patchAdminBannedArticleMutation = useMutation(
    (params) =>
      // リクエストボディ:公開状態(管理者用)
      client.patch(`/adm_article/${params.id}/`, {
        is_banned: params.is_banned,
      }),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => {
        dispatch(handlePatchAdminBannedArticleState({ updating: true }));
      },
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(
          handlePatchAdminBannedArticleState({ updating: false, updated: true })
        );
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // 記事の削除(DELETE)
  const deleteArticleMutation = useMutation(
    (id) => client.delete(`/my_article/${id}/`),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => {
        dispatch(handleDeleteArticleState({ updating: true }));
      },
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handleDeleteArticleState({ updating: false, updated: true }));
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // 管理者による記事の削除(DELETE)
  const adminDeleteArticleMutation = useMutation(
    (id) => client.delete(`/adm_article/${id}/`),
    {
      // リクエスト中状態をReduxに通知
      onMutate: () => {
        dispatch(handleDeleteArticleState({ updating: true }));
      },
      // リクエスト成功時処理
      onSuccess: () => {
        dispatch(handleDeleteArticleState({ updating: false, updated: true }));
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  return {
    postArticleMutation,
    putArticleMutation,
    patchIsPublishedArticleMutation,
    patchAdminBannedArticleMutation,
    deleteArticleMutation,
    adminDeleteArticleMutation,
  };
};
