// 共通Component_リスト画面縮小時の子要素レイアウト崩れ防止の為、Typographyを以下で囲む

// 外部ライブラリ
import { memo } from "react";
import { Box } from "@mui/material";

// ***レンダリング***
export const ScrollBox = memo(({ children, sx }) => {
  // ***props説明***
  // children: 内包する子要素やテキスト
  // sx: スタイル設定

  return <Box sx={{ flex: 1, overflow: "auto", ...sx }}>{children}</Box>;
});
