//　ナビゲーションサイドメニュー用変数_サイドメニューリスト設定

// 外部ライブラリ
import { useParams } from "react-router-dom";
import HouseIcon from "@mui/icons-material/House";
import FeedIcon from "@mui/icons-material/Feed";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import DatasetIcon from "@mui/icons-material/Dataset";
import HailRoundedIcon from "@mui/icons-material/HailRounded";
import StarIcon from "@mui/icons-material/Star";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NotificationsIcon from "@mui/icons-material/Notifications";

// メインメニューリスト
export const sideMenuMainItemsList = [
  {
    label: "HOME",
    tooltip: "ホーム画面",
    icon: <HouseIcon sx={{ fontSize: 38 }} />,
    path: "/",
  },
  {
    label: "ARTICLE",
    tooltip: "記事を探す",
    icon: <FeedIcon sx={{ fontSize: 38 }} />,
    path: "/article_search/all/index1",
  },
  {
    label: "MY PAGE",
    tooltip: "マイページ",
    icon: <EmojiEmotionsIcon sx={{ fontSize: 38 }} />,
    path: "/my_page/profile",
  },
];

// サブメニューリスト
export const sideMenuSubItemsList = (containerName) => {
  const { index } = useParams();
  const subMenuItems = {
    home: {
      label: "HOME",
      icon: <HouseIcon />,
      menuItems: [
        {
          label: "Welcome！",
          path: "/",
          active: "/",
          icon: <HailRoundedIcon />,
        },
        {
          label: "お知らせ一覧",
          path: "/home/notification/index1",
          active: `/home/notification/index${index}`,
          icon: <AnnouncementIcon />,
        },
      ],
    },
    article_search: {
      label: "ARTICLE",
      icon: <FeedIcon />,
      menuItems: [
        {
          label: "全ての記事",
          path: "/article_search/all/index1",
          active: `/article_search/all/index${index}`,
          icon: <FeedIcon />,
        },
        {
          label: "ブックマーク",
          path: "/article_search/bookmarked/index1",
          active: `/article_search/bookmarked/index${index}`,
          icon: <StarIcon />,
        },
        {
          label: "タグから探す",
          path: "/article_search/tags",
          active: "/article_search/tags",
          icon: <LocalOfferIcon />,
        },
      ],
    },
    my_page: {
      label: "MY PAGE",
      icon: <EmojiEmotionsIcon />,
      menuItems: [
        {
          label: "プロフィール",
          path: "/my_page/profile",
          active: "/my_page/profile",
          icon: <AssignmentIndIcon />,
        },
        {
          label: "自分の記事管理",
          path: "/my_page/my_article/index1",
          active: `/my_page/my_article/index${index}`,
          icon: <FeedIcon />,
        },
        {
          label: "あなたへのお知らせ",
          path: "/my_page/message",
          active: `/my_page/message`,
          icon: <NotificationsIcon />,
        },
      ],
    },
    admin: {
      label: "ADMIN",
      icon: <AdminPanelSettingsIcon />,
      menuItems: [
        {
          label: "記事管理",
          path: "/admin/article/index1",
          active: `/admin/article/index${index}`,
          icon: <FeedIcon />,
        },
        {
          label: "お知らせ管理",
          path: "/admin/notification/index1",
          active: `/admin/notification/index${index}`,
          icon: <AnnouncementIcon />,
        },
        {
          label: "アクセスログ",
          path: "/admin/access_log/index1",
          active: `/admin/access_log/index${index}`,
          icon: <DatasetIcon />,
        },
      ],
    },
  }[containerName];

  return subMenuItems;
};
