// 管理者用_お知らせ編集Dialog

// 外部ライブラリ
import { memo, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";

// プロジェクト内リソース
import {
  handleEditNotificationDialog,
  handlePutNotificationState,
  handleEditNotificationPreviewTab,
  onChangeNotificationParams,
} from "../../features/notificationStateSlice";
import { TextEditor } from "../Share/TextEditor";
import { ArticleNotificationDialogTemplate } from "../Share/ArticleNotificationDialogTemplate ";
import { CreateEditArticleNotificationDialogMainMenu } from "../Share/CreateEditArticleNotificationDialogMainMenu";
import { CreateEditNotificationDialogTitleArea } from "./CreateEditNotificationDialogTitleArea";
import { NotificationDialogTitleArea } from "./NotificationDialogTitleArea";
import { CreateEditNotificationDialogPreviewText } from "./CreateEditNotificationDialogPreviewText";

// ***レンダリング***
export const EditNotificationDialog = memo(() => {
  const dispatch = useDispatch();

  // お知らせ本文状態
  const editorCore = useRef(null);

  const {
    editNotificationDialog, // Dialog状態
    notificationParams, // 作成編集中お知らせ状態
    editNotificationPreviewTab, // プレビュータブ状態
  } = useSelector((state) => state.notificationState);

  // タイトル文字数制限
  const capsLimit = notificationParams.title.length > 50;

  // 投稿・更新ボタン無効条件
  const disableButton = // タイトル未入力
    !notificationParams.title ||
    // タイトル文字数制限超過
    capsLimit;

  // プレビューEditor本文
  const [previewText, setPreviewText] = useState();

  // プレビュー・編集選択Tab
  const handlePreviewTab = useCallback(
    async (event, value) => {
      if (!editNotificationPreviewTab) {
        const saveCore = await editorCore.current.save();
        await setPreviewText(saveCore);
        dispatch(
          onChangeNotificationParams({ text: JSON.stringify(saveCore) })
        );
      }
      dispatch(handleEditNotificationPreviewTab(value));
    },
    [editNotificationPreviewTab, editorCore]
  );

  // editor編集内容をグローバルステートへ反映
  const onClickPutNotificaiotnCheckDialogOpen = useCallback(async () => {
    if (!editNotificationPreviewTab) {
      const saveCore = await editorCore.current.save();
      dispatch(onChangeNotificationParams({ text: JSON.stringify(saveCore) }));
    }
    dispatch(handlePutNotificationState({ dialog: true }));
  }, [editNotificationPreviewTab, editorCore]);

  return (
    <>
      <ArticleNotificationDialogTemplate
        open={editNotificationDialog}
        onClickClose={() => dispatch(handleEditNotificationDialog())}
        titleContent={
          <>
            <CreateEditArticleNotificationDialogMainMenu
              label="お知らせ編集"
              handleFetchDialog={onClickPutNotificaiotnCheckDialogOpen}
              tab={editNotificationPreviewTab}
              handleTab={handlePreviewTab}
              disableButton={disableButton}
            />
            <Divider sx={{ mb: 2 }} />
            {editNotificationPreviewTab ? (
              <NotificationDialogTitleArea data={notificationParams} />
            ) : (
              <CreateEditNotificationDialogTitleArea />
            )}
            <Divider sx={{ mt: 1 }} />
          </>
        }
        bodyContent={
          editNotificationPreviewTab ? (
            <CreateEditNotificationDialogPreviewText
              previewText={previewText}
            />
          ) : (
            <TextEditor
              defaultValue={
                notificationParams.text && JSON.parse(notificationParams.text)
              }
              editorCore={editorCore}
            />
          )
        }
      />
    </>
  );
});
