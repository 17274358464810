// 管理者用_お知らせ作成編集Dialog用Component_プレビュー表示時の本文部分

// 外部ライブラリ
import { memo } from "react";

// プロジェクト内リソース
import { TextEditor } from "../Share/TextEditor";

// ***レンダリング***
export const CreateEditNotificationDialogPreviewText = memo(
  ({ previewText }) => {
    // ***props説明***
    // previewText: プレビュー表示本文

    return (
      <TextEditor
        defaultValue={previewText}
        editorCore={previewText}
        readOnly
      />
    );
  }
);
