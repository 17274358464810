// 記事削除確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

// プロジェクト内リソース
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";
import {
  handleMyArticleDialog,
  handleDeleteArticleState,
  handleAdminArticleDialog,
} from "../../features/articleStateSlice";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";

// ***レンダリング***
export const DeleteArticleCheckDialog = memo(({ id }) => {
  // ***props説明***
  // id:記事ID

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  // 記事削除APIリクエスト状態・遷移元URL
  const { deleteArticleState, previousURL } = useSelector(
    (state) => state.articleState
  );

  // 記事削除APIリクエスト処理関数
  const { deleteArticleMutation, adminDeleteArticleMutation } =
    useMutateArticleApi();

  return (
    <>
      <UpdateDbDialog
        open={deleteArticleState.dialog}
        onClickCloseDialog={() =>
          dispatch(handleDeleteArticleState({ dialog: false }))
        }
        content={<Typography color="error">この記事を削除します</Typography>}
        onClickUpdate={() => {
          pathname.includes("/admin/article")
            ? adminDeleteArticleMutation.mutate(id)
            : deleteArticleMutation.mutate(id);
        }}
        updating={deleteArticleState.updating}
        updated={deleteArticleState.updated}
        onClickCheckUpdated={() => {
          dispatch(handleDeleteArticleState({ dialog: false, updated: false }));
          if (pathname == `/article_search/${id}`) {
            previousURL
              ? navigate(previousURL)
              : navigate("/my_page/my_article/index1");
          } else {
            pathname.includes("/admin/article")
              ? dispatch(handleAdminArticleDialog())
              : dispatch(handleMyArticleDialog());
          }
        }}
      />
    </>
  );
});
