// 共通Component_リスト画面縮小時の文字崩れ防止の為、Typographyを以下で囲む

// 外部ライブラリ
import { memo } from "react";
import { Box } from "@mui/material";

// ***レンダリング***
export const ListItemBox = memo(({ children }) => {
  // ***props説明***
  // children: 内包する子要素やテキスト

  return (
    <Box
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </Box>
  );
});
