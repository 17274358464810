// 記事検索条件状態管理
import { createSlice } from "@reduxjs/toolkit";

// 初期値
const initialState = {
  // ***検索条件***
  articleSearchParams: {
    author: "", // 記事作成者
    tags: [], // タグ
    keyword: "", // キーワード
    scope: "fulltext", // 検索範囲(タイトルと本文orタイトルのみ)
    bookmarked: false, // ブックマーク記事
    myArticle: false, // 自分の記事
    or: "OR", // AND/OR検索設定
  },
};

const articleSearchParamsSlice = createSlice({
  name: "articleSearchParams",
  initialState,
  reducers: {
    // 検索条件変更
    onChangeArticleSearchParams: (state, actions) => {
      state.articleSearchParams = {
        ...state.articleSearchParams,
        ...actions.payload,
      };
    },

    // 検索条件初期化
    onClickClearArticleSearchParams: (state) => {
      state.articleSearchParams = initialState.articleSearchParams;
    },
  },
});

export const { onChangeArticleSearchParams, onClickClearArticleSearchParams } =
  articleSearchParamsSlice.actions;

export default articleSearchParamsSlice.reducer;
