// 管理者用_ADMIN/お知らせ管理用Component_お知らせ確認Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

// プロジェクト内リソース
import { useQueryAdmNotificationApi } from "../../hooks/useQueryAdmNotificationApi";
import { handleAdminNotificationDialog } from "../../features/notificationStateSlice";
import { NotificationDialog } from "../NotificationDialog/NotificationDialog";

// ***レンダリング***
export const AdminNotificationDialog = memo(() => {
  const dispatch = useDispatch();

  // Dialog状態
  const { adminNotificationDialog } = useSelector(
    (state) => state.notificationState
  );
  // 管理者用_お知らせ単体データ取得
  const { data } = useQueryAdmNotificationApi(adminNotificationDialog.id);

  return (
    <NotificationDialog
      open={adminNotificationDialog.dialog}
      onClickClose={() => dispatch(handleAdminNotificationDialog())}
      data={data}
    />
  );
});
