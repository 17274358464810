// 管理者用_ADMIN/記事管理用Component_記事参照Dialog

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

// プロジェクト内リソース
import { useQueryAdmArticleApi } from "../../hooks/useQueryAdmArticleApi";
import { ArticleDialog } from "../ArticleDialog/ArticleDialog";
import { handleAdminArticleDialog } from "../../features/articleStateSlice";

// ***レンダリング***
export const AdminArticleDialog = memo(() => {
  const dispatch = useDispatch();

  // Dialog状態
  const { adminArticleDialog } = useSelector((state) => state.articleState);

  // 管理者用_記事単体データ取得
  const { data } = useQueryAdmArticleApi(adminArticleDialog.id);

  return (
    <ArticleDialog
      open={adminArticleDialog.dialog}
      onClickClose={() => dispatch(handleAdminArticleDialog())}
      data={data}
      isAdmin
    />
  );
});
