// 複数コンポーネントで利用する日付処理に関する共通関数・変数

import dayjs from "dayjs";

export const today = dayjs().format("YYYY-MM-DD");
export const now = dayjs().format("YYYY-MM-DD HH:mm");

// 最新ニュース判定：今の所は当日
export const isRecently = (start_date) => {
  return start_date == today ? true : false;
};

// 日付をYYYY-MM-DDにフォーマット
export const dateFormatter = (date) => {
  const formatedDate = dayjs(date).format("YYYY-MM-DD");
  return formatedDate;
};
