// エントリーポイント

// 外部ライブラリ
import React from "react";
import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser"; // Azure認証用
import { ThemeProvider } from "@mui/material/styles"; // Material-UI_themeプロバイダ
import CssBaseline from "@mui/material/CssBaseline"; // Material-UI_CSSベースラインリセット

// プロジェクト内リソース
import { theme } from "./Styles/theme";
import { AuthRoutes } from "./AuthRoutes";
import { msalConfig } from "./utils/authConfig";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

export const msalInstance = new PublicClientApplication(msalConfig);

// ***レンダリング***
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AuthRoutes instance={msalInstance} />
  </ThemeProvider>
);
