// 管理者用_お知らせ単体取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getAdmNotification = async (id) => {
  const { data } = await client.get(`/adm_notification/${id}`);
  return data;
};

export const useQueryAdmNotificationApi = (id) => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["adm_notification", id],
    queryFn: () => id && getAdmNotification(id),
    cacheTime: 0,
    staleTime: 0,
  });
};
