// MY PAGE/プロフィール_アバター画像変更Dialog

// 外部ライブラリ
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Avatar } from "@mui/material";

// プロジェクト内リソース
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { handleEditAvatarDialog } from "../../features/avatarStateSlice";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import { MyPageProfileAreaUploadButton } from "./MyPageProfileAreaUploadButton";

// ***レンダリング***
export const MyPageProfilePutAvatorDialog = memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ***props説明***
  // newAvatar: 新しく選択されたアバター画像データ
  // onChangeAddImage: 画像選択時の実行関数
  // onClickPutAvatar: 更新実行時関数
  const { newAvatar, onChangeAddImage, onClickPutAvatar } = props;

  // ユーザーデータ取得
  const { data } = useQueryMeApi();

  // アバター更新APIリクエスト状態
  const { putAvatarState } = useSelector((state) => state.avatarState);

  return (
    <UpdateDbDialog
      open={putAvatarState.dialog}
      onClickCloseDialog={() => dispatch(handleEditAvatarDialog())}
      content={
        <Box>
          <Avatar
            src={
              newAvatar.length
                ? URL.createObjectURL(newAvatar[0])
                : data.user_avatar
            }
            sx={{ height: 100, width: 100, mb: 1 }}
          />
          <MyPageProfileAreaUploadButton onChange={onChangeAddImage}>
            画像を選択
          </MyPageProfileAreaUploadButton>
        </Box>
      }
      onClickUpdate={onClickPutAvatar}
      updating={putAvatarState.updating}
      updated={putAvatarState.updated}
      onClickCheckUpdated={() => navigate(0)}
      disabled={!newAvatar.length}
    />
  );
});
