//　ヘッダー用Component_未来ロゴ・APP名（KnowledgeCampus）

// 外部ライブラリ
import { memo } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// プロジェクト内リソース
import Logo from "../../../Images/logo.png";

// ***レンダリング***
export const HeaderAppName = memo(({ loading }) => {
  // ***props説明***
  // loading: データローディング状態

  const navigate = useNavigate();

  // APP名クリック時処理
  const onClickAppName = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: !loading && "pointer",
        width: 352,
        pointerEvents: loading && "none",
      }}
      onClick={onClickAppName}
    >
      <img src={Logo} width="42" height="42" />
      <Typography color="primary" variant="h5" sx={{ ml: 2 }}>
        Knowledge Campus
      </Typography>
    </Box>
  );
});
