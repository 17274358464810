// ARTICLE/記事閲覧用Component_タイトル他情報エリア_いいね部分

// 外部ライブラリ
import { memo } from "react";
import { Chip } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

// プロジェクト内リソース
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";

// ***レンダリング***
export const ArticleLikeState = memo(({ data }) => {
  // ***props説明***
  // data:記事データ

  // ユーザーデータ取得
  const me = useQueryMeApi();

  // いいね状態判定
  const isLike = data.like.includes(me.data.id);

  // いいね更新APIリクエスト関数
  const { postArticleLikeMutation } = useMutateArticleOptionsApi();

  return (
    <Chip
      color="primary"
      size="medium"
      icon={isLike ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
      label={data.like.length}
      sx={{ px: 0.6, cursor: "pointer", width: 66 }}
      onClick={() => postArticleLikeMutation.mutate(data.id)}
      variant={isLike ? "contained" : "outlined"}
      disabled={postArticleLikeMutation.isLoading}
    />
  );
});
