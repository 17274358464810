//　ナビゲーションサイドメニュー用Component_メインメニューエリア

// 外部ライブラリ
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Box, Typography, Divider, IconButton, Link } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

// プロジェクト内リソース
import { firstPathname } from "../../../utils/utils";
import { onClickCreateArticleDialogOpen } from "../../../features/articleStateSlice";
import { SideMenuItemButton } from "./SideMenuItemButton";
import { sideMenuMainItemsList } from "./SideMenuItemsList";

// ***レンダリング***
export const SideMenuMainMenuArea = memo((props) => {
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const containerName = firstPathname(pathname);

  // ***props説明***
  // data: ユーザーデータ
  // loading: データローディング状態
  const { data, loading } = props;

  // 新規記事作成Dialog表示状態
  const { createArticleDialog } = useSelector((state) => state.articleState);

  return (
    <>
      {/* 新規記事作成エリア */}
      <Box
        onClick={() => dispatch(onClickCreateArticleDialogOpen())}
        sx={{ pointerEvents: loading && "none" }}
      >
        <Box
          sx={{
            textAlign: "center",
            color: "base.main",
            opacity: loading ? 0.2 : createArticleDialog ? 1 : 0.46,
            bgcolor: createArticleDialog && "primary.main",
            ":hover": {
              opacity: 1,
              bgcolor: "sideDrawer.hover",
              cursor: !loading && "pointer",
            },
            py: 2.6,
          }}
        >
          <DriveFileRenameOutlineIcon sx={{ fontSize: 48 }} />
          <Typography variant="body2">NEW POST</Typography>
        </Box>
      </Box>
      <Divider sx={{ bgcolor: "sideDrawer.divider" }} />

      {/* メインメニュー項目リスト */}
      {sideMenuMainItemsList.map((item, index) => {
        return (
          <SideMenuItemButton
            key={index}
            item={item}
            isActive={containerName == firstPathname(item.path)}
            loading={loading}
          />
        );
      })}
      {/* 管理者用メニュー項目 */}
      {!loading && data.is_staff && (
        <SideMenuItemButton
          item={{
            label: "ADMIN",
            tooltip: "管理者画面",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 38 }} />,
            path: "/admin/article/index1",
          }}
          isActive={containerName == "admin"}
          loading={loading}
        />
      )}

      {/* ヘルプセンターリンク */}
      <Box sx={{ position: "absolute", bottom: 10, left: 12 }}>
        <IconButton
          sx={{
            pointerEvents: loading && "none",
            opacity: loading ? 0.2 : 0.4,
            color: "base.main",
            ":hover": { opacity: 1 },
          }}
          LinkComponent={Link}
          href={process.env.REACT_APP_INQUIRY_URL}
          target="_blank"
        >
          <HelpCenterIcon sx={{ fontSize: 52 }} />
        </IconButton>
      </Box>
    </>
  );
});
