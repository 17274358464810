// MY PAGE/プロフィール

// 外部ライブラリ
import { useDispatch } from "react-redux";
import { Box, Typography, Chip, IconButton, Badge } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

// プロジェクト内リソース
import { useQueryMeApi } from "../hooks/useQueryMeApi";
import { useMutateAvatarApi } from "../hooks/useMutateAvatarApi";
import { handleEditAvatarDialog } from "../features/avatarStateSlice";
import { MyPageProfilePutAvatorDialog } from "../Components/MyPage/MyPageProfilePutAvatorDialog";
import { ContentCard } from "../Components/Share/ContentCard";
import { CustomAvatar } from "../Components/Share/CustomAvatar";

// ***レンダリング***
export const MyPageProfile = () => {
  const dispatch = useDispatch();

  // ユーザーデータ取得
  const { data } = useQueryMeApi();

  // アバター画像更新APIリクエスト関数
  const { newAvatar, setNewAvatar, onChangeAddImage, putAvatarMutation } =
    useMutateAvatarApi();

  // アバター編集Dialogを開く関数
  const onClickEditAvatarDialogOpen = () => {
    dispatch(handleEditAvatarDialog());
    setNewAvatar([]);
  };

  return (
    <>
      {/* アバター編集Dialog */}
      <MyPageProfilePutAvatorDialog
        newAvatar={newAvatar}
        onChangeAddImage={onChangeAddImage}
        onClickPutAvatar={() => putAvatarMutation.mutate(data.id)}
      />
      {/* コンテナ部分 */}
      <ContentCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
            mb: 3,
          }}
        >
          <AssignmentIndIcon />
          <Typography variant="h7" sx={{ ml: 1 }}>
            プロフィール
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <IconButton onClick={onClickEditAvatarDialogOpen}>
                  <ChangeCircleIcon />
                </IconButton>
              }
            >
              <CustomAvatar data={data} sx={{ height: 118, width: 118 }} />
            </Badge>
          </Box>
          <Box ml={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: 28,
                mb: 0.6,
              }}
            >
              <PersonOutlineIcon />
              <Typography variant="h6" sx={{ ml: 1.8 }}>
                {data.display_name}
              </Typography>
              {data.is_staff && <Chip label="Admin" sx={{ ml: 2 }} />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", height: 28 }}>
              <MailOutlineIcon />
              <Typography sx={{ ml: 1.8 }}>{data.email}</Typography>
            </Box>
          </Box>
        </Box>
      </ContentCard>
    </>
  );
};
