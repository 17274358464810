// タグリスト取得用APIリクエスト関数(useQuery)

// 外部ライブラリ
import { useQuery } from "react-query";

// プロジェクト内リソース
import { client } from "../utils/authClient";

const getTag = async () => {
  const { data } = await client.get("/tag/");
  return data;
};

export const useQueryTagApi = () => {
  // ***設定値***
  // queryKey: 取得データ管理Key
  // queryFn: データ取得関数(GET)
  // cacheTime: 非マウント時のキャッシュ保持期間
  // staleTime: キャッシュデータ有効期間
  return useQuery({
    queryKey: ["tag"],
    queryFn: getTag,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
  });
};
