// ARTICLE/記事閲覧用Component_管理者用メニュー

// 外部ライブラリ
import { memo } from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Chip, Button, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// プロジェクト内リソース
import {
  handleDeleteArticleState,
  handlePatchAdminBannedArticleState,
} from "../../features/articleStateSlice";
import { PatchAdminBannedArticleCheckDialog } from "../ArticleDialog/PatchAdminBannedArticleCheckDialog";

// ***レンダリング***
export const ArticleAdminMenu = memo(({ data }) => {
  // ***props説明***
  // data:記事単体データ

  const dispatch = useDispatch();

  // 記事公開中判定
  const admUndeliteable = !data.is_banned && data.is_published;

  return (
    <>
      {/* 管理者用_公開禁止状態変更確認Dialog */}
      <PatchAdminBannedArticleCheckDialog
        params={{ id: data.id, is_banned: !data.is_banned }}
      />
      {/* 管理者用メニュー部分 */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Chip label="管理者操作" variant="outlined" />
        <Typography
          variant="body2"
          color={
            data.is_banned
              ? "error"
              : data.is_published
              ? "secondary"
              : "base.constractText"
          }
          sx={{ ml: 2, width: 100 }}
        >
          {`状態：${
            data.is_banned
              ? "公開禁止"
              : data.is_published
              ? "公開中"
              : "非公開"
          }`}
        </Typography>
        <Button
          variant="contained"
          sx={{ ml: 3.4 }}
          startIcon={
            data.is_banned ? <CheckCircleOutlineIcon /> : <BlockIcon />
          }
          color={data.is_banned ? "primary" : "error"}
          onClick={() =>
            dispatch(handlePatchAdminBannedArticleState({ dialog: true }))
          }
        >
          {data.is_banned ? "公開許可" : "公開禁止"}
        </Button>
        <Button
          variant="contained"
          sx={{ ml: 1.6 }}
          startIcon={<DeleteIcon />}
          onClick={() => dispatch(handleDeleteArticleState({ dialog: true }))}
          color="error"
          disabled={admUndeliteable}
        >
          削除
        </Button>
        {admUndeliteable && (
          <Typography
            variant="caption"
            sx={{ ml: 1, display: { xs: "none", sm: "block" } }}
          >
            ※公開中記事は削除不可
          </Typography>
        )}
      </Box>
      <Divider sx={{ mt: 1, mb: 1.6 }} />
    </>
  );
});
