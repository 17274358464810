// お知らせ参照Dialog用Component_タイトル他情報エリア

// 外部ライブラリ
import { memo } from "react";
import { Typography, Box } from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AnnouncementIcon from "@mui/icons-material/Announcement";

// プロジェクト内リソース
import { dateFormatter, isRecently } from "../../utils/datetime";

// ***レンダリング***
export const NotificationDialogTitleArea = memo(({ data }) => {
  // ***props説明***
  // data: お知らせ単体内容

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "base.contrastText",
          my: 1,
        }}
      >
        <AnnouncementIcon />
        <Typography variant="h7" sx={{ flexGrow: 1, ml: 1 }}>
          お知らせ
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", height: 26 }}>
        <Typography variant="subtitle2">
          {dateFormatter(data.start_date)}
        </Typography>
        {isRecently(data.start_date) && (
          <FiberNewIcon color="secondary" sx={{ ml: 0.6 }} />
        )}
      </Box>
      <Typography variant="h5" color="primary">
        {data.title}
      </Typography>
    </>
  );
});
