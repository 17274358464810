//　ヘッダー用Component_検索エリア_タグ検索/検索設定Popper_タグ指定部分

// 外部ライブラリ
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// プロジェクト内リソース
import { onChangeArticleSearchParams } from "../../../features/articleSearchParamsSlice";

// ***レンダリング***
export const HeaderSearchAreaTagList = ({ tagItems }) => {
  const dispach = useDispatch();

  // 検索条件パラメーター
  const { articleSearchParams } = useSelector(
    (state) => state.articleSearchParams
  );

  // タグフィルター用キーワード
  const [keyword, setKeyword] = useState("");

  // キーワード変更処理関数
  const onChangeKeyword = useCallback((event) => {
    setKeyword(event.target.value);
  }, []);

  // キーワード初期化関数
  const onClickClearKeyword = useCallback(() => {
    setKeyword("");
  }, []);

  // タグフィルター関数
  const tagFilter = useCallback(() => {
    const filteredTags = tagItems.filter((item) => {
      const re = new RegExp(keyword, "i");
      return item.name.match(re);
    });
    return filteredTags;
  }, [keyword, tagItems]);

  // タグ選択時処理関数
  const handleSelectTag = useCallback(
    (value) => {
      if (articleSearchParams.tags.includes(value)) {
        dispach(
          // タグ削除
          onChangeArticleSearchParams({
            tags: articleSearchParams.tags.filter((x) => x !== value),
          })
        );
      } else {
        dispach(
          // タグ追加
          onChangeArticleSearchParams({
            tags: [...articleSearchParams.tags, value],
          })
        );
      }
    },
    [articleSearchParams]
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <TextField
          placeholder="Filter"
          value={keyword}
          variant="standard"
          fullWidth
          onChange={onChangeKeyword}
          sx={{ mr: 2 }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={onClickClearKeyword}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <Button
          onClick={() => dispach(onChangeArticleSearchParams({ tags: [] }))}
        >
          clear
        </Button>
      </Box>
      <List disablePadding>
        <Box sx={{ height: "52vh", mt: 1, overflow: "auto" }} tabIndex={0}>
          {tagFilter().map((item, index) => {
            return (
              <Box key={index}>
                <ListItem
                  sx={{
                    cursor: "pointer",
                    bgcolor:
                      articleSearchParams.tags.includes(item) &&
                      "primary.light",
                  }}
                  onClick={() => handleSelectTag(item)}
                >
                  <ListItemText
                    primary={`#${item.name}`}
                    sx={{ color: "primary.main", fontSize: 14 }}
                  />
                </ListItem>
                <Divider />
              </Box>
            );
          })}
        </Box>
      </List>
    </>
  );
};
